import React, { useEffect, useState } from "react";
import PropType from "prop-types";
import "./ModifyMember.scss";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import BtnCloseModal from "../../../0.Commun/BtnCloseModal/BtnCloseModal";
import Catch from "../../../../0.Commun/Catch/Catch";
import { verifEmail } from "../../../../0.Commun/Verification/Regex";
import {
  messageLevel,
  messagePopup,
  openPopup,
} from "../../../../0.Commun/Redux/Action";

const API_URL = process.env.REACT_APP_API_URL;

function ModifyMember({ memberToModify, setOpenModal }) {
  const [member, setMember] = useState({
    role: "",
    sous_role: "",
    nom: "",
    email: "",
  });
  const dispatch = useDispatch();
  const history = useHistory();

  const getMember = () => {
    const token = localStorage.getItem("afmfToken");
    axios
      .get(`${API_URL}/api/organigrammes/getById/${memberToModify}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .then((data) => {
        setMember(data);
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  useEffect(() => {
    getMember();
  }, []);

  const handleMember = (e) => {
    setMember({ ...member, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    const token = localStorage.getItem("afmfToken");
    const resultRegex = verifEmail(member.email);
    if (!member.nom || !member.email) {
      dispatch(openPopup(true));
      dispatch(messagePopup(`Veuillez renseigner tous les champs`));
      dispatch(messageLevel("warning"));
    } else if (!resultRegex) {
      dispatch(openPopup(true));
      dispatch(messagePopup(`Veuillez renseigner un email correct`));
      dispatch(messageLevel("warning"));
    } else {
      axios
        .put(
          `${API_URL}/api/organigrammes/${memberToModify}`,
          {
            role: member.role,
            sousRole: member.sousRole,
            nom: member.nom,
            email: member.email,
            conseil: member.conseil,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          setOpenModal(false);
          dispatch(openPopup(true));
          dispatch(messagePopup(`Membre modifié`));
          dispatch(messageLevel("good"));
        })
        .catch((err) => {
          Catch(err, dispatch, history);
        });
    }
  };

  return (
    <section className="modifyMember">
      <div className="bg">
        <div className="containerModifyMember">
          <BtnCloseModal setOpenModal={setOpenModal} />
          <h4>Modifier un membre</h4>
          <form className="formAddMember">
            <label className="label" htmlFor="role">
              <input
                type="text"
                className="input"
                id="role"
                name="role"
                placeholder="Rôle"
                value={member.role}
                onChange={(event) => handleMember(event)}
              />
            </label>
            <label className="label" htmlFor="sousRole">
              <input
                type="text"
                className="input"
                id="sousRole"
                name="sous_role"
                placeholder="Sous rôle"
                value={member.sous_role !== null ? member.sous_role : ""}
                onChange={(event) => handleMember(event)}
              />
            </label>
            <label className="label" htmlFor="nom">
              <input
                type="text"
                className="input"
                id="nom"
                name="nom"
                placeholder="Nom"
                value={member.nom}
                onChange={(event) => handleMember(event)}
              />
            </label>
            <label className="label" htmlFor="email">
              <input
                type="text"
                className="input"
                id="email"
                name="email"
                placeholder="Email"
                value={member.email}
                onChange={(event) => handleMember(event)}
              />
            </label>
          </form>
          <button type="button" className="btnGeneral" onClick={handleSubmit}>
            Modifier
          </button>
        </div>
      </div>
    </section>
  );
}

ModifyMember.propTypes = {
  memberToModify: PropType.number.isRequired,
  setOpenModal: PropType.func.isRequired,
};

export default ModifyMember;
