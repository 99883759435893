import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import Catch from "../../0.Commun/Catch/Catch";
import Footer from "../0.Commun/Footer/Footer";
import Navbar from "../0.Commun/Navbar/Navbar";
import "./scss/OneActusDesktop.scss";
import "./scss/OneActusMobil.scss";
import { newName } from "../../0.Commun/NewName/NewName";
import CarouselAfmf from "./Carousel/Carousel";
import { scrollLock } from "../../0.Commun/scrollLock/scrollLock";

const API_URL = process.env.REACT_APP_API_URL;

function OneActus() {
  const [article, setArticle] = useState({});
  const [isLoadingArticle, setIsLoadingArticle] = useState(false);
  const [openCarousel, setOpenCarousel] = useState(false);
  const [idSelected, setIdSelected] = useState(0);
  const { id, name } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const matchImg = (data) => {
    const newArticle = [];
    data.forEach((el) => {
      const articleExist = newArticle.find((item) => item.id === el.articleId);
      const articleIndex = newArticle.findIndex(
        (item) => item.id === el.articleId
      );
      if (!articleExist) {
        newArticle.push({
          id: el.articleId,
          title: el.title,
          text: el.text,
          actus: el.actuality,
          images: [
            {
              id: el.imageId,
              name: el.name,
              alt: el.alt,
            },
          ],
        });
      } else {
        newArticle[articleIndex].images.push({
          id: el.imageId,
          name: el.name,
          alt: el.alt,
        });
      }
    });
    setArticle(Object.assign(...newArticle));
    setIsLoadingArticle(true);
  };

  const getArticle = () => {
    axios
      .get(`${API_URL}/api/articles/getOne/${id}`)
      .then((res) => res.data)
      .then((data) => {
        matchImg(data);
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  useEffect(() => {
    getArticle();
  }, []);

  useEffect(() => {
    scrollLock(openCarousel);
  }, [openCarousel]);

  const openModalCarousel = (imgId) => {
    setOpenCarousel(true);
    setIdSelected(imgId);
  };

  return (
    <section className={openCarousel ? "oneActu oneActuDisable" : "oneActu"}>
      {openCarousel === false && <Navbar />}
      <Helmet>
        <title>{name.split("_").join(" ")}</title>
      </Helmet>
      {isLoadingArticle && (
        <div className="containerGeneral">
          <h2 className="titlePage">{article.title}</h2>
          <div className="text quillParser">
            {ReactHtmlParser(article.text)}
          </div>
          <div className="containerImg">
            {article.images.map((image, index) => (
              <button
                type="button"
                className="oneImg"
                key={image.id}
                onClick={() => openModalCarousel(index)}
              >
                <img
                  src={`${API_URL}/images/${image.name}`}
                  alt={image.alt}
                  className="imgActus"
                />
                <p className="altImg">{newName(image.alt)}</p>
              </button>
            ))}
          </div>
          {openCarousel && (
            <CarouselAfmf
              images={article.images}
              setCloseModal={setOpenCarousel}
              idSelected={idSelected}
            />
          )}
          <Link
            to={article.actus === 1 ? "/actus" : "/souvenirs"}
            className="linkBack"
          >
            {article.actus === 1
              ? "Retour aux actualités"
              : "Retour aux souvenirs"}
          </Link>
          {openCarousel === false && (
            <Link
              to={article.actus === 1 ? "/actus" : "/souvenirs"}
              className="linkBackMobil"
            >
              Retour
            </Link>
          )}
        </div>
      )}
      <Footer />
    </section>
  );
}

export default OneActus;
