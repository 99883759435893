import React from "react";
import PropType from "prop-types";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.css";
import "./scss/CarouselDesktop.scss";
import "./scss/CarouselMobil.scss";
import BtnCloseModal from "../../../3.Admin/0.Commun/BtnCloseModal/BtnCloseModal";

const API_URL = process.env.REACT_APP_API_URL;

function CarouselAfmf({ images, setCloseModal, idSelected }) {
  const defineLegend = (oldName) => {
    const newName = oldName.split(".").shift();
    return newName;
  };

  return (
    <section className="carouselAfmf">
      <div className="bgFixed">
        <BtnCloseModal setOpenModal={setCloseModal} />
        <Carousel
          className="containerCarousel"
          showArrows
          showStatus
          showIndicators={false}
          infiniteLoop
          showThumbs
          useKeyboardArrows
          autoPlay
          stopOnHover
          swipeable
          emulateTouch
          autoFocus={false}
          interval={5000}
          transitionTime={500}
          swipeScrollTolerance={5}
          selectedItem={idSelected}
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                className="btnNext"
              >
                &#8250;
              </button>
            )
          }
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                className="btnPrev"
              >
                &#8249;
              </button>
            )
          }
        >
          {images.map((item) => (
            <div key={item.id}>
              <img
                src={`${API_URL}/images/${item.name}`}
                alt={item.alt}
                className="imgCarousel"
              />
              <p className="legend">{defineLegend(item.alt)}</p>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
}

CarouselAfmf.propTypes = {
  images: PropType.arrayOf(
    PropType.shape({
      id: PropType.number.isRequired,
      name: PropType.string.isRequired,
      alt: PropType.string.isRequired,
    })
  ).isRequired,
  setCloseModal: PropType.func.isRequired,
  idSelected: PropType.number.isRequired,
};

export default CarouselAfmf;
