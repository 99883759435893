import React, { useEffect } from "react";
import "./Navbar.scss";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logo } from "../../../0.Commun/Logo/DetermineLogo";
import VerifyToken from "../VerifyToken/verifyToken";

function Navbar() {
  const dispatch = useDispatch();
  const history = useHistory();
  const role = localStorage.getItem("afmfRole");

  const listNavbar = [
    {
      id: 1,
      name: "Paramètres",
      path: "/admin/params",
      access: "all",
    },
    {
      id: 2,
      name: "AFMF",
      path: "/admin/afmf",
      access: "administrateur",
    },
    {
      id: 3,
      name: "Majorettes",
      path: "/admin/majorettes",
      access: "administrateur",
    },
    {
      id: 4,
      name: "Actus/souvenirs",
      path: "/admin/actus",
      access: "administrateur",
    },
    {
      id: 5,
      name: "Sponsors",
      path: "/admin/sponsors",
      access: "administrateur",
    },
  ];

  const disconnect = () => {
    localStorage.removeItem("afmfToken");
    localStorage.removeItem("afmfId");
    localStorage.removeItem("afmfRole");
    history.push("/login");
  };

  useEffect(() => {
    VerifyToken(dispatch, history);
  }, []);

  return (
    <section className="navbarAdmin">
      <div className="containerLogo">
        <img src={logo} alt="Logo AFMF" className="logo" />
      </div>
      <div className="containerList">
        {listNavbar.map((list) => (
          <Link to={list.path} key={list.id} className="listLink">
            <p
              className={
                role !== "utilisateur" ||
                (role === "utilisateur" && list.access === "all")
                  ? "listName"
                  : "listDisable"
              }
            >
              {list.name}
            </p>
          </Link>
        ))}
        <button type="button" className="btnDisconnect" onClick={disconnect}>
          Déconnexion
        </button>
      </div>
    </section>
  );
}

export default Navbar;
