const initialState = {
  openPopupMessage: false,
  messagePopup: "",
  levelMessage: "",
  openPopupConfirm: false,
  responseConfirm: "",
  categories: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_POPUP_MESSAGE":
      return { ...state, openPopupMessage: action.openModal };
    case "MESSAGE_POPUP":
      return { ...state, messagePopup: action.message };
    case "LEVEL_MESSAGE":
      return { ...state, levelMessage: action.level };
    case "OPEN_POPUP_CONFIRM":
      return { ...state, openPopupConfirm: action.openConf };
    case "RESPONSE_CONFIRM":
      return { ...state, responseConfirm: action.response };
    case "SET_CATEGORIES":
      return { ...state, categories: action.getCategories };
    default:
      return state;
  }
};

export default reducer;
