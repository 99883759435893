import React from "react";
import "./Admin.scss";
import { Helmet } from "react-helmet";
import Navbar from "./0.Commun/Navbar/Navbar";

function Admin() {
  return (
    <section className="adminAccueil">
      <Navbar />
      <Helmet>
        <title>Admin accueil</title>
      </Helmet>
      <p className="welcome">Bienvenue</p>
    </section>
  );
}

export default Admin;
