import jwt from "jsonwebtoken";
import {
  messageLevel,
  messagePopup,
  openPopup,
} from "../../../0.Commun/Redux/Action";

function VerifyToken(dispatch, history) {
  const token = localStorage.getItem("afmfToken");
  if (token === null) {
    history.push("/login", window.scrollTo(0, 0));
    dispatch(openPopup(true));
    dispatch(messagePopup(`Votre session a expirée`));
    dispatch(messageLevel("warning"));
    localStorage.removeItem("afmfToken");
    localStorage.removeItem("afmfId");
    localStorage.removeItem("afmfRole");
  }
  const tokenExp = jwt.decode(token).exp;
  const now = new Date();
  if (now.getTime() > tokenExp * 1000) {
    history.push("/login", window.scrollTo(0, 0));
    dispatch(openPopup(true));
    dispatch(messagePopup(`Votre session a expirée`));
    dispatch(messageLevel("warning"));
    localStorage.removeItem("afmfToken");
    localStorage.removeItem("afmfId");
    localStorage.removeItem("afmfRole");
  }
}

export default VerifyToken;
