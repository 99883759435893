export const checkRegex = (emailToCheck) => {
  const regexEmail = /<|>|\*|\(|\)|\{|\}|\[|\]/;
  const resultEmail = regexEmail.test(emailToCheck);
  if (resultEmail) {
    return false;
  }
  return true;
};

export const verifEmail = (emailToCheck) => {
  const verifMail = /^[^@\s]+@[^@\s]+\.[\w]+$/;
  const resultRegex = verifMail.test(emailToCheck);
  if (!resultRegex) {
    return false;
  }
  return true;
};

export const checkPassword = (passwordToCheck) => {
  const regexPassword = /<|>|\(|\)|\{|\}|\[|\]/;
  const resultPassword = regexPassword.test(passwordToCheck);
  if (resultPassword) {
    return false;
  }
  return true;
};

export const checkPhone = (phoneToCheck) => {
  const verifPhone = /^([\d]{2}([ ]|[.]|[-])){4}([\d]{2})$/;
  const verifPhoneWithoutSeparator = /^([\d]{10})$/;
  const resultVerifOne = verifPhone.test(phoneToCheck);
  const resultVerifTwo = verifPhoneWithoutSeparator.test(phoneToCheck);
  if (resultVerifOne || resultVerifTwo) {
    return true;
  }
  return false;
};
