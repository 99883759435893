import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { combineReducers, createStore } from "redux";
import "./App.scss";
import ReactGa from "react-ga4";
import reducer from "./components/0.Commun/Redux/Reducer";
import CookieAcceptance from "./components/0.Commun/CookieAcceptance/CookieAcceptance";
import Accueil from "./components/1.Public/1.Accueil/Accueil";
import Afmf from "./components/1.Public/2.Afmf/Afmf";
import Majorettes from "./components/1.Public/3.Majorettes/Majorettes";
import Souvenirs from "./components/1.Public/4.Souvenirs/Souvenirs";
import OneActus from "./components/1.Public/5.OneActus/OneActus";
import Sponsors from "./components/1.Public/6.Sponsors/Sponsors";
import Contact from "./components/1.Public/7.Contact/Contact";
import Mentions from "./components/1.Public/8.Mentions/Mentions";
import Login from "./components/2.Login/Login";
import ForgetPassword from "./components/2.Login/components/forgetPassword/forgetPassword";
import NewPassword from "./components/2.Login/components/newPassword/newPassword";
import Admin from "./components/3.Admin/Admin";
import Profil from "./components/3.Admin/1.Profil/Profil";
import AfmfAdmin from "./components/3.Admin/2.AFMF/Afmf";
import MajorettesAdmin from "./components/3.Admin/3.Majorettes/Majorettes";
import ActusAdmin from "./components/3.Admin/4.Actus/Actus";
import SponsorsAdmin from "./components/3.Admin/5.Sponsors/Sponsors";
import NotFound from "./components/1.Public/9.NotFound/NotFound";
import PopupMessage from "./components/0.Commun/PopupMessage/PopupMessage";

const rootReducer = combineReducers({
  tasks: reducer,
});

export const store = createStore(
  rootReducer /* ,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() */
);

function App() {
  const [cookie, setCookie] = useState();

  useEffect(() => {
    if (process.env.NODE_ENV === "production" && cookie !== false) {
      ReactGa.initialize("G-V1CMSCW23H");
      ReactGa.send("pageview");
    }
  }, [cookie]);

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/" component={Accueil} />
          <Route path="/afmf" component={Afmf} />
          <Route path="/majorettes/:id/:name" component={Majorettes} />
          <Route path="/souvenirs" component={Souvenirs} />
          <Route path="/actus" component={Souvenirs} />
          <Route path="/articles/:id/:name" component={OneActus} />
          <Route path="/sponsors" component={Sponsors} />
          <Route path="/contact" component={Contact} />
          <Route path="/mentions_legales" component={Mentions} />
          <Route path="/login" component={Login} />
          <Route path="/forgetPassword" component={ForgetPassword} />
          <Route path="/newPassword/:id/:token" component={NewPassword} />
          <Route path="/admin/accueil" component={Admin} />
          <Route path="/admin/params" component={Profil} />
          <Route path="/admin/afmf" component={AfmfAdmin} />
          <Route path="/admin/majorettes" component={MajorettesAdmin} />
          <Route path="/admin/actus" component={ActusAdmin} />
          <Route path="/admin/sponsors" component={SponsorsAdmin} />
          <Route path="/not_found" component={NotFound} />
          <Redirect to="/not_found" />
        </Switch>
      </Router>
      <CookieAcceptance setCookie={setCookie} />
      <PopupMessage />
    </Provider>
  );
}

export default App;
