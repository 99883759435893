import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { BsFiletypePdf } from "react-icons/bs";
import PopupMessage from "../../0.Commun/PopupMessage/PopupMessage";
import Navbar from "../0.Commun/Navbar/Navbar";
import "./Afmf.scss";
import Catch from "../../0.Commun/Catch/Catch";
import verifyImage from "../../0.Commun/Verification/VerifyImage";
import verifyPdf from "../../0.Commun/Verification/VerifyPdf";
import {
  messageLevel,
  messagePopup,
  openPopup,
} from "../../0.Commun/Redux/Action";
import { checkRegex } from "../../0.Commun/Verification/Regex";
import Quill from "../0.Commun/Quill/Quill";
import Organigramme from "./Organigramme/Organigramme";

const API_URL = process.env.REACT_APP_API_URL;

function Afmf() {
  const [imgCto, setImgCto] = useState({});
  const [errorImg, setErrorImg] = useState(false);
  const [ctoWords, setCtoWords] = useState({
    id: 0,
    title: "",
    text: "",
  });
  const [textCtoWords, setTextCtoWords] = useState("");
  const [orga, setOrga] = useState({
    id: 0,
    title: "",
    text: "",
  });
  const [histo, setHisto] = useState({
    id: 0,
    title: "",
    text: "",
  });
  const [textHisto, setTextHisto] = useState("");
  const [imgHisto, setImgHisto] = useState({});
  const [files, setFiles] = useState({});
  const [isLoadingFiles, setIsLoadingFiles] = useState(false);
  const [newFile, setNewFile] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  const getImgCto = () => {
    axios
      .get(`${API_URL}/api/images/getPresident`)
      .then((res) => res.data)
      .then((data) => {
        setImgCto(data);
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  const getctoWords = () => {
    axios
      .get(`${API_URL}/api/otherTexts/1`)
      .then((res) => res.data)
      .then((data) => {
        setCtoWords(data);
        setTextCtoWords(data.text);
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  const getOrga = () => {
    axios
      .get(`${API_URL}/api/otherTexts/2`)
      .then((res) => res.data)
      .then((data) => {
        setOrga(data);
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  const gethisto = () => {
    axios
      .get(`${API_URL}/api/otherTexts/3`)
      .then((res) => res.data)
      .then((data) => {
        setHisto(data);
        setTextHisto(data.text);
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  const getImgHisto = () => {
    axios
      .get(`${API_URL}/api/images/getHistory`)
      .then((res) => res.data)
      .then((data) => {
        setImgHisto(data);
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  const getFiles = () => {
    axios
      .get(`${API_URL}/api/fileUpload`)
      .then((res) => res.data)
      .then((data) => {
        setFiles(data);
        setIsLoadingFiles(true);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setIsLoadingFiles(false);
          setFiles({});
        } else {
          Catch(err, dispatch, history);
        }
      });
  };

  useEffect(() => {
    getImgCto();
    getctoWords();
    getOrga();
    gethisto();
    getImgHisto();
    getFiles();
  }, []);

  const postNewImg = (newImg) => {
    const token = localStorage.getItem("afmfToken");
    const newImage = new FormData();
    newImage.append("file", newImg);
    axios
      .post(`${API_URL}/api/images/newPresident`, newImage, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        dispatch(openPopup(true));
        dispatch(messagePopup("Nouvelle photo correctement importée"));
        dispatch(messageLevel("good"));
        getImgCto();
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  const handleNewImgCto = (e) => {
    if (verifyImage(e.target.files[0]).response === false) {
      dispatch(openPopup(true));
      dispatch(messagePopup(verifyImage(e.target.files[0]).message));
      dispatch(messageLevel("warning"));
      setErrorImg(true);
    } else {
      postNewImg(e.target.files[0]);
      setErrorImg(false);
    }
  };

  const handleTitle = (e, category) => {
    const resultRegex = checkRegex(e.target.value);
    if (!resultRegex) {
      dispatch(openPopup(true));
      dispatch(messagePopup("Caractère non autorisé"));
      dispatch(messageLevel("warning"));
    } else if (category === 1) {
      setCtoWords({ ...ctoWords, title: e.target.value });
    } else if (category === 2) {
      setOrga({ ...orga, title: e.target.value });
    } else if (category === 3) {
      setHisto({ ...histo, title: e.target.value });
    }
  };

  const submitCtoWords = (category, title, text) => {
    const token = localStorage.getItem("afmfToken");
    axios
      .put(
        `${API_URL}/api/otherTexts/${category}`,
        {
          title,
          text,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        getctoWords();
        dispatch(openPopup(true));
        dispatch(messagePopup("Modification enregistrée"));
        dispatch(messageLevel("good"));
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  const postNewImgHisto = (newImg, oldImg) => {
    const token = localStorage.getItem("afmfToken");
    const newImage = new FormData();
    newImage.append("file", newImg);
    if (oldImg !== undefined) {
      axios
        .delete(`${API_URL}/api/images/${oldImg.id}/${oldImg.name}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .catch((err) => {
          Catch(err, dispatch, history);
        });
    }
    axios
      .post(`${API_URL}/api/images/images`, newImage, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .then((data) => {
        axios
          .post(
            `${API_URL}/api/images/newImg`,
            {
              name: data.imageName,
              alt: newImg.name,
              size: data.size,
              first: 0,
              president: 0,
              history: 1,
              article_id: null,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            dispatch(openPopup(true));
            dispatch(messagePopup("Nouvelle photo correctement importée"));
            dispatch(messageLevel("good"));
            getImgHisto();
          })
          .catch((err) => {
            Catch(err, dispatch, history);
          });
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  const handleNewImgHisto = (e, oldImg) => {
    if (verifyImage(e.target.files[0]).response === false) {
      dispatch(openPopup(true));
      dispatch(messagePopup(verifyImage(e.target.files[0]).message));
      dispatch(messageLevel("warning"));
    } else {
      postNewImgHisto(e.target.files[0], oldImg);
    }
  };

  const handleFile = (e) => {
    if (verifyPdf(e.target.files[0]).response === false) {
      dispatch(openPopup(true));
      dispatch(messagePopup(verifyPdf(e.target.files[0]).message));
      dispatch(messageLevel("warning"));
    } else {
      setNewFile(e.target.files[0]);
    }
  };

  const sendFile = () => {
    const token = localStorage.getItem("afmfToken");
    if (newFile.name === undefined) {
      dispatch(openPopup(true));
      dispatch(messagePopup(`Veuillez sélectionner un pdf`));
      dispatch(messageLevel("warning"));
    } else {
      const fileToSend = new FormData();
      fileToSend.append("file", newFile);
      axios
        .post(`${API_URL}/api/fileUpload`, fileToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          dispatch(openPopup(true));
          dispatch(messagePopup("Nouveau fichier correctement importé"));
          dispatch(messageLevel("good"));
          getFiles();
          setNewFile({});
        })
        .catch((err) => {
          Catch(err, dispatch, history);
        });
    }
  };

  const deleteFile = (file) => {
    const token = localStorage.getItem("afmfToken");
    axios
      .delete(`${API_URL}/api/fileUpload/${file.id}/${file.name}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        dispatch(openPopup(true));
        dispatch(messagePopup("Fichier supprimé"));
        dispatch(messageLevel("good"));
        getFiles();
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  return (
    <section className="afmfAdmin">
      <PopupMessage />
      <Navbar />
      <Helmet>
        <title>Admin - AFMF</title>
      </Helmet>
      <div className="containerTitle">
        <h2>L&#39;AFMF</h2>
      </div>
      <div className="containerImgCto">
        {imgCto.name && (
          <img
            src={`${API_URL}/images/${imgCto.name}`}
            alt="Président de l'Amicale des Fanfares et Majorettes de France"
            className="imgCto"
          />
        )}
        <form>
          <label
            htmlFor="newImgCto"
            className={
              errorImg ? "labelImgWrong labelCto" : "labelImgTrue labelCto"
            }
          >
            Choisissez une nouvelle photo
            <input
              type="file"
              name="newImgCto"
              id="newImgCto"
              accept="image/*"
              onChange={(event) => handleNewImgCto(event)}
              className="newImg"
            />
          </label>
        </form>
        <hr />
      </div>
      <div className="containerTexts">
        <form>
          <label htmlFor="textCto">
            <input
              type="text"
              id="textCto"
              name="textCto"
              value={ctoWords.title}
              onChange={(event) => handleTitle(event, ctoWords.id)}
              className="inputTitle"
            />
          </label>
        </form>
        <Quill text={textCtoWords} setText={setTextCtoWords} />
        <button
          type="button"
          className="btnGeneral"
          onClick={() =>
            submitCtoWords(ctoWords.id, ctoWords.title, textCtoWords)
          }
        >
          Modifier
        </button>
        <hr />
      </div>
      <div className="containerTexts">
        <form>
          <label htmlFor="textOrga">
            <input
              type="text"
              id="textOrga"
              name="textOrga"
              value={orga.title}
              onChange={(event) => handleTitle(event, orga.id)}
              className="inputTitle"
            />
          </label>
        </form>
        <Organigramme />
        {isLoadingFiles && (
          <div className="containerFiles">
            {files.map((file) => (
              <div key={file.id} className="btnGeneral oneFile">
                <BsFiletypePdf className="pdf" />
                <a
                  href={`${API_URL}/file_upload/${file.name}`}
                  target="blank"
                  key={file.id}
                >
                  {file.originalName}
                </a>
                <button
                  type="button"
                  onClick={() => deleteFile(file)}
                  className="deleteFile"
                >
                  &#10008;
                </button>
              </div>
            ))}
          </div>
        )}
        <form className="containerUploadFile">
          <label htmlFor="fileUpload" className="labelFile">
            {!newFile.name ? "Ajouter un fichier PDF" : `${newFile.name}`}
            <input
              type="file"
              id="fileUpload"
              name="fileUpload"
              accept="application/pdf"
              className="inputFile"
              onChange={(event) => handleFile(event)}
            />
          </label>
          <button type="button" className="btnGeneral" onClick={sendFile}>
            Ajouter
          </button>
        </form>
        <hr />
      </div>
      <div className="containerTexts">
        <form>
          <label htmlFor="textHisto">
            <input
              type="text"
              id="textHisto"
              name="textHisto"
              value={histo.title}
              onChange={(event) => handleTitle(event, histo.id)}
              className="inputTitle"
            />
          </label>
        </form>
        <Quill text={textHisto} setText={setTextHisto} />
        <button
          type="button"
          className="btnGeneral"
          onClick={() => submitCtoWords(histo.id, histo.title, textHisto)}
        >
          Modifier
        </button>
        <div className="containerImgHisto">
          <div className="imgHisto">
            {imgHisto[0] !== undefined ? (
              <form>
                <label htmlFor="imgHisto1">
                  <img
                    src={`${API_URL}/images/${imgHisto[0].name}`}
                    alt={imgHisto[0].alt}
                    className="img"
                  />
                  <input
                    type="file"
                    id="imgHisto1"
                    name="imgHisto1"
                    className="inputImg"
                    accept="image/*"
                    onChange={(event) => handleNewImgHisto(event, imgHisto[0])}
                  />
                </label>
              </form>
            ) : (
              <form>
                <label htmlFor="imgHisto2" className="labelImg">
                  &#43;
                  <input
                    type="file"
                    id="imgHisto2"
                    name="imgHisto2"
                    className="inputImg"
                    accept="image/*"
                    onChange={(event) => handleNewImgHisto(event, imgHisto[0])}
                  />
                </label>
              </form>
            )}
          </div>
          <div className="imgHisto">
            {imgHisto[1] !== undefined ? (
              <form>
                <label htmlFor="imgHisto3">
                  <img
                    src={`${API_URL}/images/${imgHisto[1].name}`}
                    alt={imgHisto[1].alt}
                    className="img"
                  />
                  <input
                    type="file"
                    id="imgHisto3"
                    name="imgHisto3"
                    className="inputImg"
                    accept="image/*"
                    onChange={(event) => handleNewImgHisto(event, imgHisto[1])}
                  />
                </label>
              </form>
            ) : (
              <form>
                <label htmlFor="imgHisto4" className="labelImg">
                  &#43;
                  <input
                    type="file"
                    id="imgHisto4"
                    name="imgHisto4"
                    className="inputImg"
                    accept="image/*"
                    onChange={(event) => handleNewImgHisto(event, imgHisto[1])}
                  />
                </label>
              </form>
            )}
          </div>
          <div className="imgHisto">
            {imgHisto[2] !== undefined ? (
              <form>
                <label htmlFor="imgHisto5">
                  <img
                    src={`${API_URL}/images/${imgHisto[2].name}`}
                    alt={imgHisto[2].alt}
                    className="img"
                  />
                  <input
                    type="file"
                    id="imgHisto5"
                    name="imgHisto5"
                    className="inputImg"
                    accept="image/*"
                    onChange={(event) => handleNewImgHisto(event, imgHisto[2])}
                  />
                </label>
              </form>
            ) : (
              <form>
                <label htmlFor="imgHisto6" className="labelImg">
                  &#43;
                  <input
                    type="file"
                    id="imgHisto6"
                    name="imgHisto6"
                    className="inputImg"
                    accept="image/*"
                    onChange={(event) => handleNewImgHisto(event, imgHisto[2])}
                  />
                </label>
              </form>
            )}
          </div>
        </div>
        <hr />
      </div>
    </section>
  );
}

export default Afmf;
