import React, { useState } from "react";
import PropType from "prop-types";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { checkRegex, verifEmail } from "../../../0.Commun/Verification/Regex";
import {
  messageLevel,
  messagePopup,
  openPopup,
} from "../../../0.Commun/Redux/Action";
import Catch from "../../../0.Commun/Catch/Catch";
import BtnCloseModal from "../../0.Commun/BtnCloseModal/BtnCloseModal";

const API_URL = process.env.REACT_APP_API_URL;

function AddUser({ setOpenModalAdd }) {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  const closeModal = () => {
    setOpenModalAdd(false);
  };

  const handleEmail = (e) => {
    const resultCheck = checkRegex(e.target.value);
    if (!resultCheck) {
      dispatch(openPopup(true));
      dispatch(messagePopup(`Caractère utilisé non autorisé`));
      dispatch(messageLevel("warning"));
    } else {
      setEmail(e.target.value);
    }
  };

  const handleSelect = (e) => {
    setRole(e.target.value);
  };

  const handlePassword = (e) => {
    const resultCheck = checkRegex(e.target.value);
    if (!resultCheck) {
      dispatch(openPopup(true));
      dispatch(messagePopup(`Caractère utilisé non autorisé`));
      dispatch(messageLevel("warning"));
    } else {
      setPassword(e.target.value);
    }
  };

  const handleSubmit = () => {
    const token = localStorage.getItem("afmfToken");
    const checkEmail = verifEmail(email);
    if (!email || !role || !password) {
      dispatch(openPopup(true));
      dispatch(messagePopup(`Veuillez renseigner tous les champs`));
      dispatch(messageLevel("warning"));
    } else if (!checkEmail) {
      dispatch(openPopup(true));
      dispatch(messagePopup(`Veuillez renseigner un email valide`));
      dispatch(messageLevel("warning"));
    } else {
      axios
        .post(
          `${API_URL}/api/users/newUser`,
          {
            email,
            role,
            password,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          dispatch(openPopup(true));
          dispatch(messagePopup(`Nouvel utilisateur ajouté`));
          dispatch(messageLevel("good"));
          closeModal();
        })
        .catch((err) => {
          Catch(err, dispatch, history);
        });
    }
  };

  return (
    <section className="addUserGlobal">
      <div className="bg">
        <div className="containerAddUser">
          <BtnCloseModal setOpenModal={setOpenModalAdd} />
          <h3 className="title">Ajouter un utilisateur</h3>
          <form className="containerFormAdd">
            <label htmlFor="email" className="labelUser">
              <p className={email ? "placeholderDisplay" : "placeholderHidden"}>
                Email
              </p>
              <input
                type="text"
                id="email"
                name="email"
                className="inputUser"
                placeholder="Email"
                value={email}
                onChange={(e) => handleEmail(e)}
              />
            </label>
            <label htmlFor="role" className="labelUser">
              <select
                name="role"
                id="role"
                className="selectRole"
                onChange={(e) => handleSelect(e)}
              >
                <option value="0">Rôle</option>
                <option value="admin">Administrateur</option>
                <option value="utilisateur">Utilisateur</option>
              </select>
            </label>
            <label htmlFor="password" className="labelUser">
              <p
                className={
                  password ? "placeholderDisplay" : "placeholderHidden"
                }
              >
                Mot de passe
              </p>
              <input
                type="password"
                id="password"
                name="password"
                className="inputUser"
                placeholder="Mot de passe"
                value={password}
                onChange={(e) => handlePassword(e)}
              />
            </label>
          </form>
          <div className="containerBtnAdd">
            <button type="button" className="btnGeneral" onClick={handleSubmit}>
              Ajouter
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

AddUser.propTypes = {
  setOpenModalAdd: PropType.func.isRequired,
};

export default AddUser;
