import { messageLevel, messagePopup, openPopup } from "../Redux/Action";

function Catch(err, dispatch, history) {
  if (err.response.status === 401) {
    history.push("/login", window.scrollTo(0, 0));
    dispatch(openPopup(true));
    dispatch(messagePopup(`Votre session a expirée`));
    dispatch(messageLevel("warning"));
    localStorage.removeItem("afmfToken");
    localStorage.removeItem("afmfId");
    localStorage.removeItem("afmfRole");
  } else {
    dispatch(openPopup(true));
    dispatch(messagePopup(err.response.data.errorMessage));
    dispatch(messageLevel("warning"));
  }
}

export default Catch;
