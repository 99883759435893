import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Catch from "../../../0.Commun/Catch/Catch";
import {
  messageLevel,
  messagePopup,
  openPopup,
} from "../../../0.Commun/Redux/Action";
import { checkRegex } from "../../../0.Commun/Verification/Regex";

const API_URL = process.env.REACT_APP_API_URL;

function ModifyProfil() {
  const [email, setEmail] = useState("");
  const [newPword, setNewPword] = useState("");
  const [verifPword, setVerifPword] = useState("");
  const [pwordDisplay, setPwordDisplay] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const token = localStorage.getItem("afmfToken");
  const userId = localStorage.getItem("afmfId");

  const getUser = () => {
    axios
      .get(`${API_URL}/api/users/getOne/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .then((data) => {
        setEmail(data.email);
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  useEffect(() => {
    getUser();
  }, []);

  const displayPword = () => {
    setPwordDisplay(!pwordDisplay);
  };

  const handleEmail = (e) => {
    const resultCheck = checkRegex(e.target.value);
    if (!resultCheck) {
      dispatch(openPopup(true));
      dispatch(messagePopup(`Caractère utilisé non autorisé`));
      dispatch(messageLevel("warning"));
    } else {
      setEmail(e.target.value);
    }
  };

  const handlePassword = (e) => {
    const resultCheck = checkRegex(e.target.value);
    if (!resultCheck) {
      dispatch(openPopup(true));
      dispatch(messagePopup(`Caractère utilisé non autorisé`));
      dispatch(messageLevel("warning"));
    } else {
      setNewPword(e.target.value);
    }
  };

  const handleVerifPassword = (e) => {
    const resultCheck = checkRegex(e.target.value);
    if (!resultCheck) {
      dispatch(openPopup(true));
      dispatch(messagePopup(`Caractère utilisé non autorisé`));
      dispatch(messageLevel("warning"));
    } else {
      setVerifPword(e.target.value);
    }
  };

  const handleSubmit = () => {
    if (!email || !newPword) {
      dispatch(openPopup(true));
      dispatch(
        messagePopup(`Vous devez renseigner un e-mail et un mot de passe`)
      );
      dispatch(messageLevel("warning"));
    } else if (newPword !== verifPword) {
      dispatch(openPopup(true));
      dispatch(messagePopup(`Les mots de passes ne correspondent pas`));
      dispatch(messageLevel("warning"));
    } else {
      axios
        .put(
          `${API_URL}/api/users/user/${userId}`,
          {
            email,
            password: newPword,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          dispatch(openPopup(true));
          dispatch(messagePopup(`Modification effectuées`));
          dispatch(messageLevel("good"));
        })
        .catch((err) => {
          Catch(err, dispatch, history);
        });
    }
  };

  return (
    <section>
      <div className="containerForm">
        <h3 className="title">Modifier mes informations d&#39;accès</h3>
        <form className="formModifyProfil">
          <label htmlFor="email" className="labelForm">
            <p className={email ? "placeholderDisplay" : "placeholderHidden"}>
              E-mail
            </p>
            <input
              type="text"
              value={email}
              onChange={(e) => handleEmail(e)}
              className="inputProfil"
              placeholder="E-mail"
            />
          </label>
          <label htmlFor="password" className="labelForm">
            <p
              className={newPword ? "placeholderDisplay" : "placeholderHidden"}
            >
              Mot de passe
            </p>
            <input
              type={pwordDisplay ? "text" : "password"}
              value={newPword}
              onChange={(e) => handlePassword(e)}
              className="inputProfil"
              placeholder="Mot de passe"
            />
          </label>
          <label htmlFor="verifPassword" className="labelForm">
            <p
              className={
                verifPword ? "placeholderDisplay" : "placeholderHidden"
              }
            >
              Vérification mot de passe
            </p>
            <input
              type={pwordDisplay ? "text" : "password"}
              value={verifPword}
              onChange={(e) => handleVerifPassword(e)}
              className="inputProfil"
              placeholder="Vérification mot de passe"
            />
            <button
              type="button"
              className="pwordDisplay"
              onClick={displayPword}
            >
              Afficher le mot de passe
            </button>
          </label>
        </form>
        <button type="button" className="btnGeneral" onClick={handleSubmit}>
          Modifier
        </button>
      </div>
    </section>
  );
}

export default ModifyProfil;
