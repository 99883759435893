import React from "react";
import { Link } from "react-router-dom";
import "./scss/FooterDesktop.scss";
import "./scss/FooterMobil.scss";
import fb from "../../../../assets/Fb.png";
import dwm from "../../../../assets/logoDwM.png";

function Footer() {
  return (
    <section className="footer">
      <Link to="/mentions_legales" className="linkFooter">
        Mentions légales
      </Link>
      <Link to="/contact" className="linkFooter">
        Contact
      </Link>
      <a
        href="https://www.facebook.com/groups/283002673625292"
        target="blank"
        className="linkFooter"
      >
        <img src={fb} alt="Facebook" className="logoFb" />
      </a>
      <div className="containerMaker">
        <p className="subtitle">Site réalisé par</p>
        <a href="https://www.damienmauger.fr" target="blank">
          <img
            src={dwm}
            alt="Damien Mauger Développement Web"
            className="logoDwM"
          />
        </a>
      </div>
    </section>
  );
}

export default Footer;
