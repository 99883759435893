import React, { useEffect, useState } from "react";
import PropType from "prop-types";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import NavbarDesktop from "./NavbarDesktop/NavbarDesktop";
import NavbarMobil from "./NavbarMobil/NavbarMobil";
import Catch from "../../../0.Commun/Catch/Catch";

const API_URL = process.env.REACT_APP_API_URL;

function Navbar({ accueil }) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleScroll = () => {
    const scroll = window.scrollY;
    setScrollPosition(scroll);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getOldArticle = () => {
    axios.get(`${API_URL}/api/articles/oldArticle`).catch((err) => {
      Catch(err, dispatch, history);
    });
  };

  useEffect(() => {
    getOldArticle();
  }, []);

  const determineNavbar = () => {
    const screenSize = window.screen.width;
    if (scrollPosition < 600 && accueil && screenSize > 810) {
      return <NavbarDesktop accueil />;
    }
    if (scrollPosition > 600 && accueil && screenSize > 810) {
      return <NavbarDesktop accueil displayBackground />;
    }
    if (scrollPosition < 400 && accueil && screenSize <= 810) {
      return <NavbarMobil accueil />;
    }
    if (scrollPosition > 400 && accueil && screenSize <= 810) {
      return <NavbarMobil accueil displayBackground />;
    }
    if (screenSize <= 810) {
      return <NavbarMobil />;
    }
    return <NavbarDesktop />;
  };

  return <section>{determineNavbar()}</section>;
}

Navbar.propTypes = {
  accueil: PropType.bool,
};

Navbar.defaultProps = {
  accueil: false,
};

export default Navbar;
