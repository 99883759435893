function verifyImage(imageToVerify) {
  if (
    imageToVerify.type !== "image/jpeg" &&
    imageToVerify.type !== "image/jpg" &&
    imageToVerify.type !== "image/png"
  ) {
    return { response: false, message: `Mauvais format d'image` };
  }
  return true;
}

export default verifyImage;
