import React, { useState, useEffect } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Catch from "../../0.Commun/Catch/Catch";
import PopupMessage from "../../0.Commun/PopupMessage/PopupMessage";
import Navbar from "../0.Commun/Navbar/Navbar";
import "./Actus.scss";
import AddArticle from "./components/AddArticle";
import Filter from "../../0.Commun/Filter/Filter";
import {
  messageLevel,
  messagePopup,
  openConfirm,
  openPopup,
} from "../../0.Commun/Redux/Action";
import ModifyArticle from "./components/ModifyArticle";
import PopupConfirm from "../../0.Commun/PopupConfirm/PopupConfirm";
import { scrollLock } from "../../0.Commun/scrollLock/scrollLock";

const API_URL = process.env.REACT_APP_API_URL;

function Actus() {
  const [openCreateArticle, setOpenCreateArticle] = useState(false);
  const [actuChecked, setActuChecked] = useState(true);
  const [souvenirChecked, setSouvenirChecked] = useState(false);
  const [articles, setArticles] = useState({});
  const [isLoadingArticle, setIsLoadingArticle] = useState(false);
  const [themeSelected, setThemeSelected] = useState(0);
  const [openModifyArticle, setOpenModifyArticle] = useState(false);
  const [articleToModify, setArticleToModify] = useState(0);
  const [articleToDelete, setArticleToDelete] = useState(0);
  const confirm = useSelector((state) => state.tasks.responseConfirm);
  const dispatch = useDispatch();
  const history = useHistory();

  const openModal = () => {
    setOpenCreateArticle(true);
  };

  const handleActusChecked = (e) => {
    if (e.target.checked === true) {
      setActuChecked(true);
      setSouvenirChecked(false);
    } else {
      setActuChecked(false);
      setSouvenirChecked(true);
    }
  };

  const handleSouvenirChecked = (e) => {
    if (e.target.checked === true) {
      setActuChecked(false);
      setSouvenirChecked(true);
    } else {
      setActuChecked(true);
      setSouvenirChecked(false);
    }
  };

  const getArticles = () => {
    let bool = 1;
    if (souvenirChecked) {
      bool = 0;
    }
    if (themeSelected === 0) {
      axios
        .get(`${API_URL}/api/articles/getAll?actu=${bool}&length=1000`)
        .then((res) => res.data)
        .then((data) => {
          setArticles(data);
          setIsLoadingArticle(true);
        })
        .catch((err) => {
          Catch(err, dispatch, history);
        });
    } else {
      axios
        .get(
          `${API_URL}/api/articles/getByTheme?themeId=${themeSelected}&actu=${bool}&length=50`
        )
        .then((res) => res.data)
        .then((data) => {
          setArticles(data);
          setIsLoadingArticle(true);
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(openPopup(true));
            dispatch(
              messagePopup(`Il n'y a pas de contenus pour cette catégorie`)
            );
            dispatch(messageLevel("warning"));
          } else {
            Catch(err, dispatch, history);
          }
        });
    }
  };

  useEffect(() => {
    getArticles();
  }, [
    openCreateArticle,
    actuChecked,
    souvenirChecked,
    themeSelected,
    openModifyArticle,
  ]);

  const openModify = (articleId) => {
    setOpenModifyArticle(true);
    setArticleToModify(articleId);
  };

  const deleteArticle = (articleId) => {
    setArticleToDelete(articleId);
    dispatch(openConfirm(true));
    dispatch(messagePopup(`Etes-vous sur de vouloir supprimer cet article ?`));
  };

  const confirmYes = () => {
    const token = localStorage.getItem("afmfToken");
    if (confirm === "true" && articleToDelete !== 0) {
      axios
        .delete(`${API_URL}/api/articles/${articleToDelete}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          dispatch(openPopup(true));
          dispatch(messagePopup(`Article supprimé`));
          dispatch(messageLevel("good"));
          getArticles();
        })
        .catch((err) => {
          Catch(err, dispatch, history);
        });
    }
  };

  useEffect(() => {
    if (confirm) {
      confirmYes();
    }
  }, [confirm]);

  useEffect(() => {
    scrollLock(openCreateArticle);
  }, [openCreateArticle]);

  useEffect(() => {
    scrollLock(openModifyArticle);
  }, [openModifyArticle]);

  return (
    <section className="actusAdmin">
      <PopupMessage />
      <PopupConfirm />
      <Navbar />
      <Helmet>
        <title>Admin - Actualités/Souvenirs</title>
      </Helmet>
      <div className="containerTitle">
        <h2>Actualités/Souvenirs</h2>
      </div>
      <div className="openModal">
        <button type="button" className="openModalBtn" onClick={openModal}>
          Ecrire un nouvel article
        </button>
      </div>
      {openCreateArticle && (
        <AddArticle setOpenCreateArticle={setOpenCreateArticle} />
      )}
      <form className="filter">
        <label htmlFor="actus" className="labelFilter">
          <input
            type="checkbox"
            id="actus"
            name="actus"
            className="inputFilter"
            checked={actuChecked}
            onChange={(event) => handleActusChecked(event)}
          />
          <p>Actualités</p>
        </label>
        <label htmlFor="souvenirs" className="labelFilter">
          <input
            type="checkbox"
            id="souvenirs"
            name="souvenirs"
            className="inputFilter"
            checked={souvenirChecked}
            onChange={(event) => handleSouvenirChecked(event)}
          />
          <p>Souvenirs</p>
        </label>
      </form>
      <div className="containerArticleFilter">
        {isLoadingArticle && (
          <div className="containerMapArticle">
            {articles.map((article) => (
              <div key={article.articleId} className="oneArticle">
                <img
                  src={`${API_URL}/images/${article.name}`}
                  alt={article.alt}
                  className="imgArticle"
                />
                <h4 className="titleArticle">{article.title}</h4>
                <button
                  type="button"
                  className="btnGeneral btnModify"
                  onClick={() => openModify(article.articleId)}
                >
                  Modifier
                </button>
                <button
                  type="button"
                  className="btnGeneral btnDelete"
                  onClick={() => deleteArticle(article.articleId)}
                >
                  Supprimer
                </button>
              </div>
            ))}
          </div>
        )}
        {openModifyArticle && (
          <ModifyArticle
            setOpenModifyArticle={setOpenModifyArticle}
            articleToModify={articleToModify}
          />
        )}
        <Filter setThemeSelected={setThemeSelected} />
      </div>
    </section>
  );
}

export default Actus;
