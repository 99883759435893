import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Catch from "../../../0.Commun/Catch/Catch";
import AddUser from "./AddUser";
import {
  messageLevel,
  messagePopup,
  openConfirm,
  openPopup,
} from "../../../0.Commun/Redux/Action";
import PopupConfirm from "../../../0.Commun/PopupConfirm/PopupConfirm";
import ModifyUser from "./ModifyUser";
import { scrollLock } from "../../../0.Commun/scrollLock/scrollLock";

const API_URL = process.env.REACT_APP_API_URL;

function UserTable() {
  const [users, setUsers] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalModify, setOpenModalModify] = useState(false);
  const [idToDelete, setIdToDelete] = useState(0);
  const [idToModify, setIdToModify] = useState(0);
  const confirm = useSelector((state) => state.tasks.responseConfirm);
  const history = useHistory();
  const dispatch = useDispatch();
  const userRole = localStorage.getItem("afmfRole");
  const userId = localStorage.getItem("afmfId");

  const getUsers = () => {
    const token = localStorage.getItem("afmfToken");
    axios
      .get(`${API_URL}/api/users/getAll`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .then((data) => {
        setUsers(data);
      })
      .then(() => {
        setIsLoading(true);
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  useEffect(() => {
    getUsers();
  }, [openModalAdd, openModalModify]);

  const openAddUser = () => {
    setOpenModalAdd(!openModalAdd);
  };

  const deleteUser = (id) => {
    setIdToDelete(id);
    dispatch(openConfirm(true));
    dispatch(
      messagePopup(`Etes-vous sur de vouloir supprimer cet utilisateur ?`)
    );
  };

  const confirmYes = () => {
    const token = localStorage.getItem("afmfToken");
    if (confirm === "true" && idToDelete !== 0) {
      axios
        .delete(`${API_URL}/api/users/${idToDelete}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          getUsers();
          dispatch(openPopup(true));
          dispatch(messagePopup(`Utilisateur supprimé`));
          dispatch(messageLevel("good"));
          setIdToDelete(0);
        })
        .catch((err) => {
          Catch(err, dispatch, history);
        });
    }
  };

  useEffect(() => {
    if (confirm) {
      confirmYes();
    }
  }, [confirm]);

  const modifyOneUser = (id) => {
    setIdToModify(id);
    setOpenModalModify(true);
  };

  useEffect(() => {
    scrollLock(openModalAdd);
  }, [openModalAdd]);

  useEffect(() => {
    scrollLock(openModalModify);
  }, [openModalModify]);

  return (
    <div className="listUsers">
      <PopupConfirm />
      <h3 className="title">Liste des utilisateurs</h3>
      {isLoading && (
        <table className="tableUser">
          <thead>
            <tr>
              <th>Id</th>
              <th>E-mail</th>
              <th>Rôle</th>
              <th>Modifier</th>
              <th>Supprimer</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.email}</td>
                <td>{user.role}</td>
                <td>
                  <button
                    type="button"
                    className={
                      userRole === "utilisateur" ||
                      parseInt(userId, 10) === user.id ||
                      user.role === "super_admin"
                        ? "btnDisabled"
                        : "btnModify"
                    }
                    onClick={() => modifyOneUser(user.id)}
                  >
                    &#10003;
                  </button>
                </td>
                <td>
                  <button
                    type="button"
                    className={
                      userRole === "utilisateur" ||
                      parseInt(userId, 10) === user.id ||
                      user.role === "super_admin"
                        ? "btnDisabled"
                        : "btnModify"
                    }
                    onClick={() => deleteUser(user.id)}
                  >
                    &#10008;
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {userRole !== "utilisateur" && (
        <button type="button" className="btnGeneral" onClick={openAddUser}>
          Ajouter un utilisateur
        </button>
      )}
      {openModalAdd && <AddUser setOpenModalAdd={setOpenModalAdd} />}
      {openModalModify && (
        <ModifyUser
          setOpenModalModify={setOpenModalModify}
          idToModify={idToModify}
        />
      )}
    </div>
  );
}

export default UserTable;
