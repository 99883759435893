import React, { useEffect, useState } from "react";
import PropType from "prop-types";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import BtnCloseModal from "../../0.Commun/BtnCloseModal/BtnCloseModal";
import Quill from "../../0.Commun/Quill/Quill";
import Catch from "../../../0.Commun/Catch/Catch";
import { checkRegex } from "../../../0.Commun/Verification/Regex";
import {
  messageLevel,
  messagePopup,
  openPopup,
} from "../../../0.Commun/Redux/Action";
import verifyImage from "../../../0.Commun/Verification/VerifyImage";
import Loader from "../../../0.Commun/Loader/Loader";
import MyCalendar from "../../../0.Commun/Calendar/MyCalendar";

const API_URL = process.env.REACT_APP_API_URL;

function AddArticle({ setOpenCreateArticle }) {
  const [themes, setThemes] = useState({});
  const [isLoadingTheme, setIsLoadingThemes] = useState(false);
  const [themeSelected, setThemeSelected] = useState(0);
  const [title, setTitle] = useState("");
  const [date, setDate] = useState(new Date().toLocaleDateString());
  const [textArticle, setTextArticle] = useState("");
  const [isActus, setIsActus] = useState(0);
  const [newImg, setNewImg] = useState([]);
  const [loaderOn, setLoaderOn] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const getThemes = () => {
    axios
      .get(`${API_URL}/api/themes`)
      .then((res) => res.data)
      .then((data) => {
        setThemes(data);
        setIsLoadingThemes(true);
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  useEffect(() => {
    getThemes();
  }, []);

  const handleTheme = (e) => {
    setThemeSelected(e.target.value);
  };

  const handleTitle = (e) => {
    const result = checkRegex(e.target.value);
    if (!result) {
      dispatch(openPopup(true));
      dispatch(messagePopup(`Caractère utilisé non autorisé`));
      dispatch(messageLevel("warning"));
    } else {
      setTitle(e.target.value);
    }
  };

  const handleIsActus = (e) => {
    if (e.target.checked === true) {
      setIsActus(1);
    } else {
      setIsActus(0);
    }
  };

  const handleImg = (e) => {
    const imgArray = [];
    try {
      for (let i = 0; i < e.target.files.length; i += 1) {
        if (verifyImage(e.target.files[i]).response === false) {
          dispatch(openPopup(true));
          dispatch(messagePopup(verifyImage(e.target.files[i]).message));
          dispatch(messageLevel("warning"));
        } else {
          imgArray.push(e.target.files[i]);
        }
      }
    } catch {
      dispatch(openPopup(true));
      dispatch(messagePopup(`Une erreur est survenue, veuillez recommencer`));
      dispatch(messageLevel("warning"));
    } finally {
      setNewImg(imgArray);
    }
  };

  const closeModal = () => {
    setOpenCreateArticle(false);
  };

  const addArticleDone = () => {
    dispatch(openPopup(true));
    dispatch(messagePopup(`Nouvel article ajouté`));
    dispatch(messageLevel("good"));
  };

  const defineFirstImg = (imgId, i) => {
    const token = localStorage.getItem("afmfToken");
    if (i === 0) {
      axios
        .put(
          `${API_URL}/api/images/${imgId}`,
          {
            first: 1,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .catch((err) => {
          Catch(err, dispatch, history);
        });
    }
  };

  const postImage = (articleId) => {
    const token = localStorage.getItem("afmfToken");
    for (let i = 0; i < newImg.length; i += 1) {
      const newImage = new FormData();
      newImage.append("file", newImg[i]);
      axios
        .post(`${API_URL}/api/images/images`, newImage, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data)
        .then((data) => {
          axios
            .post(
              `${API_URL}/api/images/newImg`,
              {
                name: data.imageName,
                alt: newImg[i].name,
                size: data.size,
                first: 0,
                president: 0,
                history: 0,
                articleId,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => res.data)
            .then((dataTwo) => {
              defineFirstImg(dataTwo.id, i);
              if (i === newImg.length - 1) {
                addArticleDone();
                setTimeout(closeModal, 1500);
              }
            })
            .catch((err) => {
              Catch(err, dispatch, history);
            });
        })
        .catch((err) => {
          Catch(err, dispatch, history);
        });
    }
  };

  const handleSubmit = () => {
    const token = localStorage.getItem("afmfToken");
    if (themeSelected === 0 || !title || !textArticle) {
      dispatch(openPopup(true));
      dispatch(messagePopup(`Vous devez remplir dans les champs`));
      dispatch(messageLevel("warning"));
    } else if (newImg.length === 0) {
      dispatch(openPopup(true));
      dispatch(messagePopup(`Veuillez sélectionner des photos`));
      dispatch(messageLevel("warning"));
    } else {
      axios
        .post(
          `${API_URL}/api/articles`,
          {
            date: date.split("/").reverse().join("-"),
            title,
            text: textArticle,
            actuality: isActus,
            themeId: themeSelected,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => res.data)
        .then((data) => {
          postImage(data.id);
        })
        .then(() => {
          setLoaderOn(true);
        })
        .catch((err) => {
          Catch(err, dispatch, history);
        });
    }
  };

  return (
    <section className="addArticleGlobal">
      {loaderOn && <Loader />}
      <div className="bg">
        <div className="containerAddArticle">
          <BtnCloseModal setOpenModal={setOpenCreateArticle} />
          {isLoadingTheme && (
            <select
              name="theme"
              id="theme"
              onChange={(event) => handleTheme(event)}
              className="selectTheme"
            >
              <option value="0" hidden>
                Choisir une catégorie
              </option>
              {themes.map((theme) => (
                <option key={theme.id} value={theme.id}>
                  {theme.name}
                </option>
              ))}
            </select>
          )}
          <form className="formArticle">
            <label htmlFor="title">
              <input
                type="text"
                id="title"
                name="title"
                placeholder="Titre"
                className="inputTitle"
                value={title}
                onChange={(event) => handleTitle(event)}
              />
            </label>
            <MyCalendar date={date} setDate={setDate} from="create" />
            <Quill text={textArticle} setText={setTextArticle} />
            <label htmlFor="actu" className="labelCheckbox">
              Actualité ?
              <input
                type="checkbox"
                id="actu"
                name="actu"
                className="inputCheckbox"
                onChange={(event) => handleIsActus(event)}
              />
            </label>
            <label htmlFor="addImg" className="labelNewImg">
              {newImg.length > 0
                ? `${newImg.length} photos sélectionnées`
                : "Veuillez sélectionner vos photos"}
              <input
                type="file"
                accept="image/*"
                id="addImg"
                name="addImg"
                className="newImg"
                onChange={(event) => handleImg(event)}
                multiple
              />
            </label>
          </form>
          <button type="button" className="btnGeneral" onClick={handleSubmit}>
            Ajouter
          </button>
        </div>
      </div>
    </section>
  );
}

AddArticle.propTypes = {
  setOpenCreateArticle: PropType.func.isRequired,
};

export default AddArticle;
