import React, { useState, useEffect } from "react";
import PropType from "prop-types";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Catch from "../Catch/Catch";
import "./Filter.scss";

const API_URL = process.env.REACT_APP_API_URL;

function Filter({ setThemeSelected }) {
  const [themes, setThemes] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [unselect, setUnselect] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();

  const makeNewList = (oldList) => {
    const newArray = [];
    oldList.forEach((element) => {
      newArray.push({
        id: element.id,
        name: element.name,
        isChecked: false,
      });
    });
    setThemes(newArray);
    setIsLoading(true);
  };

  const getThemes = () => {
    axios
      .get(`${API_URL}/api/themes`)
      .then((res) => res.data)
      .then((data) => {
        makeNewList(data);
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  useEffect(() => {
    getThemes();
  }, []);

  const handleFilter = (themeId) => {
    const newArray = [];
    let zeroSelected = true;
    for (let i = 0; i < themes.length; i += 1) {
      if (themeId === themes[i].id) {
        newArray.push({
          id: themes[i].id,
          name: themes[i].name,
          isChecked: true,
        });
        setUnselect(false);
        zeroSelected = false;
      } else {
        newArray.push({
          id: themes[i].id,
          name: themes[i].name,
          isChecked: false,
        });
      }
    }
    if (zeroSelected) {
      setUnselect(true);
    }
    setThemes(newArray);
    setThemeSelected(themeId);
  };

  return (
    <section className="containerFilter">
      <div>
        <h3 className="title">Filtre</h3>
        {isLoading && (
          <form>
            <label htmlFor="unselect" className="labelFilter">
              <input
                type="checkbox"
                className="inputFilter"
                id="unselect"
                name="unselect"
                onChange={() => handleFilter(0)}
                checked={unselect}
              />
              <p className="subtitle">Tout</p>
            </label>
            {themes.map((theme) => (
              <label
                htmlFor={theme.name}
                key={theme.id}
                className="labelFilter"
              >
                <input
                  type="checkbox"
                  className="inputFilter"
                  id={theme.name}
                  name={theme.name}
                  onChange={() => handleFilter(theme.id)}
                  checked={theme.isChecked}
                />
                <p className="subtitle">{theme.name}</p>
              </label>
            ))}
          </form>
        )}
      </div>
    </section>
  );
}

Filter.propTypes = {
  setThemeSelected: PropType.func.isRequired,
};

export default Filter;
