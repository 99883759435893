export const openPopup = (openModal) => {
  return {
    type: "OPEN_POPUP_MESSAGE",
    openModal,
  };
};

export const messagePopup = (message) => {
  return {
    type: "MESSAGE_POPUP",
    message,
  };
};

export const messageLevel = (level) => {
  return {
    type: "LEVEL_MESSAGE",
    level,
  };
};

export const openConfirm = (openConf) => {
  return {
    type: "OPEN_POPUP_CONFIRM",
    openConf,
  };
};

export const responseConfirm = (response) => {
  return {
    type: "RESPONSE_CONFIRM",
    response,
  };
};

export const updateCategories = (getCategories) => {
  return {
    type: "SET_CATEGORIES",
    getCategories,
  };
};
