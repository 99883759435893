import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import Catch from "../../0.Commun/Catch/Catch";
import Footer from "../0.Commun/Footer/Footer";
import Navbar from "../0.Commun/Navbar/Navbar";
import "./scss/MentionsDesktop.scss";
import "./scss/MentionsMobil.scss";

const API_URL = process.env.REACT_APP_API_URL;

function Mentions() {
  const [infos, setInfos] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const getInfos = () => {
    axios
      .get(`${API_URL}/api/otherTexts/4`)
      .then((res) => res.data)
      .then((data) => {
        setInfos(data);
        setIsLoading(true);
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  useEffect(() => {
    getInfos();
  }, []);

  return (
    <section className="legalNotice">
      <Navbar />
      <Helmet>
        <title>Mentions légales AFMF</title>
      </Helmet>
      {isLoading && (
        <div className="containerGeneral">
          <h2 className="title">Mentions Légales</h2>
          <div className="text quillParser">{ReactHtmlParser(infos.text)}</div>
        </div>
      )}
      <Footer />
    </section>
  );
}

export default Mentions;
