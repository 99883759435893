import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Catch from "../../../0.Commun/Catch/Catch";
import PopupConfirm from "../../../0.Commun/PopupConfirm/PopupConfirm";
import {
  messageLevel,
  messagePopup,
  openPopup,
} from "../../../0.Commun/Redux/Action";
import { scrollLock } from "../../../0.Commun/scrollLock/scrollLock";
import AddMember from "./AddMember/AddMember";
import CardAdmin from "./Card/CardAdmin";
import ModifyMember from "./ModifyMember/ModifyMember";
import "./Organigramme.scss";

const API_URL = process.env.REACT_APP_API_URL;

function Organigramme() {
  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [addMember, setAddMember] = useState({
    status: "",
    type: "",
  });
  const [memberToDelete, setMemberToDelete] = useState(0);
  const [openModify, setOpenModify] = useState(false);
  const [memberToModify, setMemberToModify] = useState(0);
  const confirm = useSelector((state) => state.tasks.responseConfirm);
  const dispatch = useDispatch();
  const history = useHistory();

  const getMembers = () => {
    axios
      .get(`${API_URL}/api/organigrammes/getAll`)
      .then((res) => res.data)
      .then((data) => {
        setMembers(data);
        setIsLoading(true);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setAddMember({
            status: "Président",
            type: "conseil",
          });
          setOpenAdd(true);
        } else {
          Catch(err, dispatch, history);
        }
      });
  };

  useEffect(() => {
    getMembers();
  }, [openAdd, openModify]);

  const createNewMember = (cat) => {
    setAddMember({
      status: "",
      type: cat,
    });
    setOpenAdd(true);
  };

  const deleteMember = () => {
    const token = localStorage.getItem("afmfToken");
    axios
      .delete(`${API_URL}/api/organigrammes/${memberToDelete}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        getMembers();
        dispatch(openPopup(true));
        dispatch(messagePopup(`Membre supprimé`));
        dispatch(messageLevel("good"));
        setMemberToDelete(0);
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  useEffect(() => {
    if (confirm) {
      deleteMember();
    }
  }, [confirm]);

  useEffect(() => {
    scrollLock(openAdd);
  }, [openAdd]);

  useEffect(() => {
    scrollLock(openModify);
  }, [openModify]);

  return (
    <section className="orgaAdmin">
      <PopupConfirm />
      {openAdd && (
        <AddMember
          status={addMember.status}
          type={addMember.type}
          setOpenModal={setOpenAdd}
        />
      )}
      {openModify && (
        <ModifyMember
          memberToModify={memberToModify}
          setOpenModal={setOpenModify}
        />
      )}
      {isLoading && (
        <div className="containerCards">
          <div className="containerCat">
            <div className="containerSubtitle">
              <h4 className="subTitle">Conseil d&#39;administration</h4>
              <button
                className="addNewMember"
                type="button"
                onClick={() => createNewMember("conseil")}
              >
                +
              </button>
            </div>
            <CardAdmin
              member={members[0]}
              setMemberToDelete={setMemberToDelete}
              setOpenModify={setOpenModify}
              setMemberToModify={setMemberToModify}
            />
            <div className="containerConseil">
              {members
                .filter((item) => item.conseil === "conseil" && item.id !== 1)
                .map((member) => (
                  <div key={member.id} className="organigramme">
                    <CardAdmin
                      member={member}
                      setMemberToDelete={setMemberToDelete}
                      setOpenModify={setOpenModify}
                      setMemberToModify={setMemberToModify}
                    />
                  </div>
                ))}
            </div>
          </div>
          <div className="containerCat">
            <div className="containerSubtitle">
              <h4 className="subTitle">Membres accesseurs</h4>
              <button
                className="addNewMember"
                type="button"
                onClick={() => createNewMember("membre")}
              >
                +
              </button>
            </div>
            <div className="containerConseil">
              {members
                .filter((item) => item.conseil === "membre" && item.id !== 1)
                .map((member) => (
                  <div key={member.id} className="organigramme">
                    <CardAdmin
                      member={member}
                      setMemberToDelete={setMemberToDelete}
                      setOpenModify={setOpenModify}
                      setMemberToModify={setMemberToModify}
                    />
                  </div>
                ))}
            </div>
          </div>
          <div className="containerCat">
            <div className="containerSubtitle">
              <h4 className="subTitle">Controleur aux comptes</h4>
              <button
                className="addNewMember"
                type="button"
                onClick={() => createNewMember("controleur")}
              >
                +
              </button>
            </div>
            <div className="containerConseil">
              {members
                .filter(
                  (item) => item.conseil === "controleur" && item.id !== 1
                )
                .map((member) => (
                  <div key={member.id} className="organigramme">
                    <CardAdmin
                      member={member}
                      setMemberToDelete={setMemberToDelete}
                      setOpenModify={setOpenModify}
                      setMemberToModify={setMemberToModify}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default Organigramme;
