import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import PropType from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { logo } from "../../../../0.Commun/Logo/DetermineLogo";
import "./scss/NavbarMobilDesktop.scss";
import "./scss/NavbarMobilMobil.scss";
import fb from "../../../../../assets/Fb.png";
import { updateCategories } from "../../../../0.Commun/Redux/Action";
import Catch from "../../../../0.Commun/Catch/Catch";

const API_URL = process.env.REACT_APP_API_URL;

function NavbarMobil({ displayBackground, accueil }) {
  const [menu, setMenu] = useState(false);
  const [openHover, setOpenHover] = useState(false);
  const categories = useSelector((state) => state.tasks.categories);
  const dispatch = useDispatch();
  const history = useHistory();

  const openMenu = () => {
    setMenu(true);
  };

  const closeMenu = () => {
    setMenu(false);
  };

  const determineClass = () => {
    if (!displayBackground && accueil) {
      return "displayBgNone";
    }
    if (displayBackground && accueil) {
      return "displayBg";
    }
    return "displayNormal";
  };

  const openHoverModal = () => {
    setOpenHover(!openHover);
  };

  const getCategories = () => {
    if (categories.length === undefined) {
      axios
        .get(`${API_URL}/api/categories/getAll`)
        .then((res) => res.data)
        .then((data) => {
          dispatch(updateCategories(data));
        })
        .catch((err) => {
          Catch(err, dispatch, history);
        });
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div className={determineClass()}>
      <section className="navMobil">
        <div className="containerLogo">
          <img
            src={logo}
            alt="Amicale des Fanfares et Majorettes de France - AFMF"
            className="logo"
          />
        </div>
        <button type="button" className="containerSpan" onClick={openMenu}>
          <span className="bar" />
          <span className="bar" />
          <span className="bar" />
        </button>
        <section className={menu ? "menu" : "menuHidden"}>
          <button type="button" className="closeBtn" onClick={closeMenu}>
            &#10007;
          </button>
          <Link to="/">
            <img
              src={logo}
              alt="Amicale des Fanfares et Majorettes de France - AFMF"
              className="logoMenu"
            />
          </Link>
          <Link to="/" className="item">
            Amicale des Fanfares et Majorettes de France
          </Link>
          <Link to="/afmf" className="item">
            AFMF
          </Link>
          {accueil ? (
            <a href="#majorettes" className="item">
              Majorettes
            </a>
          ) : (
            <div className="containerHover">
              <button
                type="button"
                className="itemBtn"
                onClick={openHoverModal}
              >
                Majorettes
              </button>
              {openHover && (
                <div className="openHover">
                  {categories.map((category) => (
                    <Link
                      to={`/majorettes/${category.id}/${category.title.replace(
                        /\s/g,
                        "_"
                      )}`}
                      className="categoryTitle"
                      key={category.id}
                      onClick={closeMenu}
                    >
                      {category.title}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          )}
          <Link to="/souvenirs" className="item">
            Souvenirs
          </Link>
          <Link to="/sponsors" className="item">
            Sponsors
          </Link>
          <Link to="/contact" className="item">
            Contact
          </Link>
          <a
            href="https://www.facebook.com/groups/283002673625292"
            target="blank"
          >
            <img src={fb} alt="Facebook" className="logoFb" />
          </a>
        </section>
      </section>
    </div>
  );
}

NavbarMobil.propTypes = {
  displayBackground: PropType.bool,
  accueil: PropType.bool,
};

NavbarMobil.defaultProps = {
  displayBackground: false,
  accueil: false,
};

export default NavbarMobil;
