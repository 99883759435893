import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openPopup } from "../Redux/Action";
import "./PopupMessage.scss";

function PopupMessage() {
  const openModalPopup = useSelector((state) => state.tasks.openPopupMessage);
  const messagePopup = useSelector((state) => state.tasks.messagePopup);
  const messageLevel = useSelector((state) => state.tasks.levelMessage);
  const dispatch = useDispatch();

  const closePopup = () => {
    dispatch(openPopup(false));
  };

  useEffect(() => {
    if (openModalPopup === true) {
      setTimeout(closePopup, 5000);
    }
  }, [openModalPopup]);

  return (
    <div className="popupMessageGlobal">
      <div className={openModalPopup ? "openModalTrue" : "openModalFalse"}>
        <p
          className={
            messageLevel === "warning" ? "warningMessage" : "goodMessage"
          }
        >
          &#9888;
        </p>
        <p className="message">{messagePopup}</p>
      </div>
    </div>
  );
}

export default PopupMessage;
