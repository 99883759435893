import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Catch from "../../0.Commun/Catch/Catch";
import { newName } from "../../0.Commun/NewName/NewName";
import PopupMessage from "../../0.Commun/PopupMessage/PopupMessage";
import {
  messageLevel,
  messagePopup,
  openPopup,
} from "../../0.Commun/Redux/Action";
import verifyImage from "../../0.Commun/Verification/VerifyImage";
import Navbar from "../0.Commun/Navbar/Navbar";
import "./Sponsors.scss";

const API_URL = process.env.REACT_APP_API_URL;

function Sponsors() {
  const [sponsors, setSponsors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [newSponsor, setNewSponsor] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  const getSponsors = () => {
    axios
      .get(`${API_URL}/api/sponsors`)
      .then((res) => res.data)
      .then((data) => {
        setSponsors(data);
        setIsLoading(true);
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  useEffect(() => {
    getSponsors();
  }, []);

  const handleImg = (e) => {
    if (verifyImage(e.target.files[0]).response === false) {
      dispatch(openPopup(true));
      dispatch(messagePopup(verifyImage(e.target.files[0]).message));
      dispatch(messageLevel("warning"));
    } else {
      setNewSponsor(e.target.files[0]);
    }
  };

  const addNewSponsor = () => {
    const token = localStorage.getItem("afmfToken");
    if (newSponsor.name === undefined) {
      dispatch(openPopup(true));
      dispatch(messagePopup("Veuillez choisir un sponsor"));
      dispatch(messageLevel("warning"));
    } else {
      const newImg = new FormData();
      newImg.append("file", newSponsor);
      axios
        .post(`${API_URL}/api/sponsors`, newImg, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          dispatch(openPopup(true));
          dispatch(messagePopup("Nouveau sponsor ajouté"));
          dispatch(messageLevel("good"));
          setNewSponsor({});
          getSponsors();
        })
        .catch((err) => {
          Catch(err, dispatch, history);
        });
    }
  };

  const deleteSponsor = (sponsor) => {
    const token = localStorage.getItem("afmfToken");
    axios
      .delete(`${API_URL}/api/sponsors/${sponsor.id}/${sponsor.image_name}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        dispatch(openPopup(true));
        dispatch(messagePopup("Sponsor supprimé"));
        dispatch(messageLevel("good"));
        getSponsors();
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  return (
    <section className="sponsorsAdmin">
      <PopupMessage />
      <Navbar />
      <Helmet>
        <title>Admin - Sponsors</title>
      </Helmet>
      <form className="containerAdd">
        <label htmlFor="addSponsor" className="labelAddSponsor">
          {newSponsor.name !== undefined
            ? `${newSponsor.name}`
            : "Ajouter un nouveau sponsor"}
          <input
            type="file"
            accept="image/*"
            id="addSponsor"
            name="addSponsor"
            className="newImg"
            onChange={(event) => handleImg(event)}
          />
        </label>
        <button
          type="button"
          className="btnGeneral btnAddSponsor"
          onClick={addNewSponsor}
        >
          Ajouter
        </button>
      </form>
      <div className="sponsorDisplay">
        {isLoading && (
          <div className="containerSponsors">
            {sponsors.map((sponsor) => (
              <div className="oneSponsor" key={sponsor.id}>
                <img
                  src={`${API_URL}/images/${sponsor.image_name}`}
                  alt={sponsor.image_alt}
                  className="sponsorImg"
                />
                <p>{newName(sponsor.image_alt)}</p>
                <button
                  type="button"
                  className="btnDeleteSponsor"
                  onClick={() => deleteSponsor(sponsor)}
                >
                  &#128465;
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
}

export default Sponsors;
