import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Recaptcha from "react-recaptcha";
import Catch from "../../0.Commun/Catch/Catch";
import Loader from "../../0.Commun/Loader/Loader";
import PopupMessage from "../../0.Commun/PopupMessage/PopupMessage";
import {
  messageLevel,
  messagePopup,
  openPopup,
} from "../../0.Commun/Redux/Action";
import { checkRegex, verifEmail } from "../../0.Commun/Verification/Regex";
import Footer from "../0.Commun/Footer/Footer";
import Navbar from "../0.Commun/Navbar/Navbar";
import "./scss/ContactDesktop.scss";
import "./scss/ContactMobil.scss";

const API_URL = process.env.REACT_APP_API_URL;

function Contact() {
  const [subjects, setSubjects] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [newMail, setNewMail] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [isVerified, setIsVerified] = useState(false);
  const [loaderOn, setLoaderOn] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const getSubjects = () => {
    axios
      .get(`${API_URL}/api/mail_subjects`)
      .then((res) => res.data)
      .then((data) => {
        setSubjects(data);
        setIsLoading(true);
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  useEffect(() => {
    getSubjects();
  }, []);

  const handleName = (e) => {
    const result = checkRegex(e.target.value);
    if (!result) {
      dispatch(openPopup(true));
      dispatch(messagePopup("Caractère non autorisé"));
      dispatch(messageLevel("warning"));
    } else {
      setNewMail({ ...newMail, name: e.target.value });
    }
  };

  const handleMail = (e) => {
    const result = checkRegex(e.target.value);
    if (!result) {
      dispatch(openPopup(true));
      dispatch(messagePopup("Caractère non autorisé"));
      dispatch(messageLevel("warning"));
    } else {
      setNewMail({ ...newMail, email: e.target.value });
    }
  };

  const handleSubject = (e) => {
    setNewMail({ ...newMail, subject: e.target.value });
  };

  const handleMessage = (e) => {
    const result = checkRegex(e.target.value);
    if (!result) {
      dispatch(openPopup(true));
      dispatch(messagePopup("Caractère non autorisé"));
      dispatch(messageLevel("warning"));
    } else {
      setNewMail({ ...newMail, message: e.target.value });
    }
  };

  const verifyCallback = () => {
    setIsVerified(true);
  };

  const handleSubmit = () => {
    const result = verifEmail(newMail.email);
    if (!isVerified) {
      dispatch(openPopup(true));
      dispatch(messagePopup("Veuillez cocher le recaptcha"));
      dispatch(messageLevel("warning"));
    } else if (
      !newMail.name ||
      !newMail.email ||
      !newMail.subject ||
      !newMail.message
    ) {
      dispatch(openPopup(true));
      dispatch(messagePopup("Veuillez remplir tous les champs"));
      dispatch(messageLevel("warning"));
    } else if (!result) {
      dispatch(openPopup(true));
      dispatch(messagePopup("Veuillez saisir un email valide"));
      dispatch(messageLevel("warning"));
    } else {
      setLoaderOn(true);
      axios
        .post(`${API_URL}/api/mails`, {
          name: newMail.name,
          email: newMail.email,
          subject: newMail.subject,
          message: newMail.message,
        })
        .then(() => {
          dispatch(openPopup(true));
          dispatch(messagePopup("Message envoyé"));
          dispatch(messageLevel("good"));
          setNewMail({
            name: "",
            email: "",
            subject: "",
            message: "",
          });
          setLoaderOn(false);
        })
        .catch((err) => {
          Catch(err, dispatch, history);
        });
    }
  };

  return (
    <section className="contactPublic">
      <PopupMessage />
      {loaderOn && <Loader />}
      <Navbar />
      <Helmet>
        <title>Contacter l&#39;AFMF</title>
      </Helmet>
      <h2 className="title">Nous contacter</h2>
      {isLoading && (
        <form className="formContact">
          <label htmlFor="name" className="labelContact">
            <input
              type="text"
              className="inputContact"
              value={newMail.name}
              placeholder="Nom/prénom"
              onChange={(event) => handleName(event)}
            />
          </label>
          <label htmlFor="email" className="labelContact">
            <input
              type="text"
              className="inputContact"
              value={newMail.email}
              placeholder="Email"
              onChange={(event) => handleMail(event)}
            />
          </label>
          <select
            name="objet"
            id="objet"
            className="selectContact"
            onChange={(event) => handleSubject(event)}
          >
            <option value="" hidden>
              Veuillez sélectionner un objet
            </option>
            {subjects.map((subject) => (
              <option value={subject.name} key={subject.id}>
                {subject.name}
              </option>
            ))}
          </select>
          <label htmlFor="message" className="labelContact">
            <textarea
              className="inputMessage"
              value={newMail.message}
              placeholder="Message..."
              onChange={(event) => handleMessage(event)}
            />
          </label>
          <Recaptcha
            sitekey="6Ld_7zceAAAAABvzwwcictW7BrasJP1QOI-i0WRj"
            render="explicit"
            hl="fr"
            verifyCallback={verifyCallback}
          />
          <button
            type="button"
            className="btnGeneral btnSubmitForm"
            onClick={handleSubmit}
          >
            Envoyer
          </button>
        </form>
      )}
      <Footer />
    </section>
  );
}

export default Contact;
