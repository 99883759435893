import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { openConfirm, responseConfirm } from "../Redux/Action";
import "./PopupConfirm.scss";

function PopupConfirm() {
  const openModalConfirm = useSelector((state) => state.tasks.openPopupConfirm);
  const messagePopup = useSelector((state) => state.tasks.messagePopup);
  const dispatch = useDispatch();

  const closePopup = (value) => {
    dispatch(openConfirm(false));
    dispatch(responseConfirm(value.target.value));
  };

  return (
    <div className={openModalConfirm ? "containerPopupConfirm" : "displayNone"}>
      <div className="containerTextPopupConfirm">
        <p className="bodyFirst">{messagePopup}</p>
        <div className="containerBtns">
          <button
            type="button"
            className="btnYesNoConfirm"
            value="true"
            onClick={closePopup}
          >
            Oui
          </button>
          <button
            type="button"
            className="btnYesNoConfirm"
            value="false"
            onClick={closePopup}
          >
            Non
          </button>
        </div>
      </div>
    </div>
  );
}

export default PopupConfirm;
