import React from "react";
import ReactQuill from "react-quill";
import PropType from "prop-types";
import "react-quill/dist/quill.snow.css";
import "./Quill.scss";

function Quill({ text, setText }) {
  const toolbarOptions = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "link"],
      [{ align: [] }],
      [{ color: [] }],
      [{ list: "bullet" }],
    ],
  };

  return (
    <div className="containerQuill">
      <ReactQuill
        theme="snow"
        modules={toolbarOptions}
        value={text}
        className="quillDisplay"
        onChange={setText}
      />
    </div>
  );
}

Quill.propTypes = {
  text: PropType.string.isRequired,
  setText: PropType.func.isRequired,
};

export default Quill;
