import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import PropType from "prop-types";
import "./MyCalendar.scss";
import "react-calendar/dist/Calendar.css";

function MyCalendar({ date, setDate, from }) {
  const [openCal, setOpenCal] = useState(false);
  const [dateOn, setDateOn] = useState(new Date());

  const openCalModal = () => {
    setOpenCal(!openCal);
  };

  const changeDate = (dateVal) => {
    if (from === "create") {
      setDate(dateVal.toLocaleDateString());
    }
    if (from === "modify") {
      setDate(dateVal);
    }
    setDateOn(dateVal);
    setOpenCal(false);
  };

  useEffect(() => {
    if (from === "modify") {
      setDateOn(new Date(date));
    }
  }, []);

  return (
    <div className="containerCal">
      <button type="button" onClick={openCalModal} className="btnDate">
        {from === "create" ? date : new Date(date).toLocaleDateString()}
      </button>
      <div className="cal">
        {openCal && <Calendar value={dateOn} onChange={changeDate} />}
      </div>
    </div>
  );
}

MyCalendar.propTypes = {
  setDate: PropType.func.isRequired,
  date: PropType.oneOfType([PropType.string, PropType.instanceOf(Date)])
    .isRequired,
  from: PropType.string.isRequired,
};

export default MyCalendar;
