import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Catch from "../../0.Commun/Catch/Catch";
import PopupMessage from "../../0.Commun/PopupMessage/PopupMessage";
import {
  messageLevel,
  messagePopup,
  openPopup,
} from "../../0.Commun/Redux/Action";
import { checkRegex } from "../../0.Commun/Verification/Regex";
import verifyImage from "../../0.Commun/Verification/VerifyImage";
import verifyPdf from "../../0.Commun/Verification/VerifyPdf";
import Navbar from "../0.Commun/Navbar/Navbar";
import Quill from "../0.Commun/Quill/Quill";
import "./Majorettes.scss";

const API_URL = process.env.REACT_APP_API_URL;

function Majorettes() {
  const [categories, setCategories] = useState({});
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [categoryDisplay, setCategoryDisplay] = useState({});
  const [text, setText] = useState("");
  const [textComing, setTextComing] = useState("");
  const [isLoadingDisplay, setIsLoadingDisplay] = useState(false);
  const [errorImg, setErrorImg] = useState(false);
  const [newFile, setNewFile] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  const getCartegories = () => {
    axios
      .get(`${API_URL}/api/categories/getAll`)
      .then((res) => res.data)
      .then((data) => {
        setCategories(data);
        setIsLoadingCategories(true);
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  useEffect(() => {
    getCartegories();
  }, []);

  const handleCategories = (e, from) => {
    let id;
    if (from === "select") {
      id = e.target.value;
    }
    if (from === "changes") {
      id = e;
    }
    axios
      .get(`${API_URL}/api/categories/getOne/${id}`)
      .then((res) => res.data)
      .then((data) => {
        setCategoryDisplay(data);
        setText(data.text);
        setTextComing(data.coming_text);
        setIsLoadingDisplay(true);
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  const postNewImg = (newImg) => {
    const token = localStorage.getItem("afmfToken");
    const newImage = new FormData();
    newImage.append("file", newImg);
    let newHtml;
    if (categoryDisplay.image_name === null) {
      newHtml = `newImg/${categoryDisplay.id}`;
    } else {
      newHtml = `images/${categoryDisplay.id}/${categoryDisplay.image_name}`;
    }
    axios
      .put(`${API_URL}/api/categories/${newHtml}`, newImage, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        dispatch(openPopup(true));
        dispatch(messagePopup("Nouvelle photo correctement importée"));
        dispatch(messageLevel("good"));
        handleCategories(categoryDisplay.id, "changes");
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  const handleNewImgCover = (e) => {
    if (verifyImage(e.target.files[0]).response === false) {
      dispatch(openPopup(true));
      dispatch(messagePopup(verifyImage(e.target.files[0]).message));
      dispatch(messageLevel("warning"));
      setErrorImg(true);
    } else {
      postNewImg(e.target.files[0]);
      setErrorImg(false);
    }
  };

  const handleTitle = (e) => {
    const result = checkRegex(e.target.value);
    if (!result) {
      dispatch(openPopup(true));
      dispatch(messagePopup(`Caractère utilisé non autorisé`));
      dispatch(messageLevel("warning"));
    } else {
      setCategoryDisplay({ ...categoryDisplay, title: e.target.value });
    }
  };

  const handleComing = (e) => {
    if (e.target.checked === true) {
      setCategoryDisplay({ ...categoryDisplay, coming_display: 1 });
    } else {
      setCategoryDisplay({ ...categoryDisplay, coming_display: 0 });
    }
  };

  const handleTitleComing = (e) => {
    const result = checkRegex(e.target.value);
    if (!result) {
      dispatch(openPopup(true));
      dispatch(messagePopup(`Caractère utilisé non autorisé`));
      dispatch(messageLevel("warning"));
    } else {
      setCategoryDisplay({ ...categoryDisplay, coming_title: e.target.value });
    }
  };

  const submitNewInfo = () => {
    const token = localStorage.getItem("afmfToken");
    if (categoryDisplay.title === "" || text === "") {
      dispatch(openPopup(true));
      dispatch(messagePopup(`Vous devez avoir un titre et un texte`));
      dispatch(messageLevel("warning"));
    } else if (
      (categoryDisplay.coming_title === "" &&
        categoryDisplay.coming_display === 1) ||
      (textComing === "" && categoryDisplay.coming_display === 1)
    ) {
      dispatch(openPopup(true));
      dispatch(
        messagePopup(
          `Vous devez avoir un titre et un texte pour la partie évènement`
        )
      );
      dispatch(messageLevel("warning"));
    } else if (
      (categoryDisplay.coming_title === "" &&
        categoryDisplay.coming_display === 0) ||
      (textComing === "" && categoryDisplay.coming_display === 0)
    ) {
      axios
        .put(
          `${API_URL}/api/categories/data/${categoryDisplay.id}`,
          {
            title: categoryDisplay.title,
            text,
            comingDisplay: categoryDisplay.coming_display,
            comingTitle: "A compléter",
            comingText: "A compléter",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          dispatch(openPopup(true));
          dispatch(messagePopup(`Modification sauvegardée`));
          dispatch(messageLevel("good"));
          handleCategories(categoryDisplay.id, "changes");
        })
        .catch((err) => {
          Catch(err, dispatch, history);
        });
    } else {
      axios
        .put(
          `${API_URL}/api/categories/data/${categoryDisplay.id}`,
          {
            title: categoryDisplay.title,
            text,
            comingDisplay: categoryDisplay.coming_display,
            comingTitle: categoryDisplay.coming_title,
            comingText: textComing,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          dispatch(openPopup(true));
          dispatch(messagePopup(`Modification sauvegardée`));
          dispatch(messageLevel("good"));
          handleCategories(categoryDisplay.id, "changes");
        })
        .catch((err) => {
          Catch(err, dispatch, history);
        });
    }
  };

  const deleteFile = (file) => {
    const token = localStorage.getItem("afmfToken");
    axios
      .put(
        `${API_URL}/api/categories/deleteFile/${categoryDisplay.id}/${file}`,
        {
          filename: null,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        dispatch(openPopup(true));
        dispatch(messagePopup("Fichier supprimé"));
        dispatch(messageLevel("good"));
        handleCategories(categoryDisplay.id, "changes");
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  const handleFile = (e) => {
    if (verifyPdf(e.target.files[0]).response === false) {
      dispatch(openPopup(true));
      dispatch(messagePopup(verifyPdf(e.target.files[0]).message));
      dispatch(messageLevel("warning"));
    } else {
      setNewFile(e.target.files[0]);
    }
  };

  const sendFile = () => {
    const token = localStorage.getItem("afmfToken");
    if (newFile.name === undefined) {
      dispatch(openPopup(true));
      dispatch(messagePopup(`Veuillez sélectionner un pdf`));
      dispatch(messageLevel("warning"));
    } else {
      const fileToSend = new FormData();
      fileToSend.append("file", newFile);
      let newHtml;
      if (categoryDisplay.filename === null) {
        newHtml = `newFile/${categoryDisplay.id}`;
      } else {
        newHtml = `file/${categoryDisplay.id}/${categoryDisplay.filename}`;
      }
      axios
        .put(`${API_URL}/api/categories/${newHtml}`, fileToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          dispatch(openPopup(true));
          dispatch(messagePopup("Nouveau fichier correctement importé"));
          dispatch(messageLevel("good"));
          handleCategories(categoryDisplay.id, "changes");
          setNewFile({});
        })
        .catch((err) => {
          Catch(err, dispatch, history);
        });
    }
  };

  return (
    <section className="majoAdmin">
      <PopupMessage />
      <Navbar />
      <Helmet>
        <title>Admin - Majorettes</title>
      </Helmet>
      {isLoadingCategories && (
        <div className="containerSelect">
          <select
            name="categories"
            id="categories"
            onChange={(event) => handleCategories(event, "select")}
            className="selectCategories"
          >
            <option value="" hidden>
              Veuillez choisir une categorie
            </option>
            {categories.map((category) => (
              <option value={category.id} key={category.id}>
                {category.title}
              </option>
            ))}
          </select>
        </div>
      )}
      {isLoadingDisplay && (
        <div className="containerCategories">
          <div className="containerImg">
            {categoryDisplay.image_name !== null && (
              <img
                src={`${API_URL}/images/${categoryDisplay.image_name}`}
                alt={categoryDisplay.image_alt}
                className="imgCover"
              />
            )}
            <form>
              <label
                htmlFor="imgCover"
                className={
                  errorImg
                    ? "labelImgWrong labelCover"
                    : "labelImgTrue labelCover"
                }
              >
                Choisissez une nouvelle photo
                <input
                  type="file"
                  className="newImgCover"
                  id="imgCover"
                  name="imgCover"
                  accept="image/*"
                  onChange={(event) => handleNewImgCover(event)}
                />
              </label>
            </form>
          </div>
          <form className="formMajo">
            <label htmlFor="title">
              <input
                type="text"
                value={categoryDisplay.title}
                id="title"
                name="title"
                className="inputTitle"
                onChange={(event) => handleTitle(event)}
              />
            </label>
            <Quill text={text} setText={setText} />
            <label htmlFor="checkboxMajo" className="labelCheckbox">
              Afficher encart évènement ?
              <input
                type="checkbox"
                className="inputCheckbox"
                id="checkboxMajo"
                name="checkboxMajo"
                checked={categoryDisplay.coming_display}
                onChange={(event) => handleComing(event)}
              />
            </label>
            {categoryDisplay.coming_display === 1 && (
              <div className="formComing">
                <label htmlFor="titleComing" className="title">
                  <input
                    type="text"
                    className="inputTitle"
                    id="titleComing"
                    name="titleComing"
                    value={categoryDisplay.coming_title}
                    onChange={(event) => handleTitleComing(event)}
                  />
                </label>
                <Quill text={textComing} setText={setTextComing} />
              </div>
            )}
          </form>
          <button type="button" className="btnGeneral" onClick={submitNewInfo}>
            Modifier
          </button>
          {categoryDisplay.filename !== null && (
            <div className="containerFile">
              <a
                href={`${API_URL}/file_upload/${categoryDisplay.filename}`}
                target="blank"
              >
                {categoryDisplay.filename}
              </a>
              <button
                type="button"
                onClick={() => deleteFile(categoryDisplay.filename)}
                className="deleteFile"
              >
                &#10008;
              </button>
            </div>
          )}
          <form className="containerUploadFile">
            <label htmlFor="fileUpload" className="labelFile">
              {!newFile.name ? "Ajouter un fichier PDF" : `${newFile.name}`}
              <input
                type="file"
                id="fileUpload"
                name="fileUpload"
                accept="application/pdf"
                className="inputFile"
                onChange={(event) => handleFile(event)}
              />
            </label>
            <button type="button" className="btnGeneral" onClick={sendFile}>
              Ajouter
            </button>
          </form>
        </div>
      )}
    </section>
  );
}

export default Majorettes;
