import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useHistory, Link } from "react-router-dom";
import { RiEyeCloseFill, RiEyeFill } from "react-icons/ri";
import { logo } from "../0.Commun/Logo/DetermineLogo";
import { checkRegex } from "../0.Commun/Verification/Regex";
import {
  openPopup,
  messagePopup,
  messageLevel,
} from "../0.Commun/Redux/Action";
import PopupMessage from "../0.Commun/PopupMessage/PopupMessage";
import Catch from "../0.Commun/Catch/Catch";
import "./scss/LoginDesktop.scss";
import "./scss/LoginMobil.scss";

const API_URL = process.env.REACT_APP_API_URL;

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [pwordDisplay, setPwordDisplay] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const message = () => {
    dispatch(openPopup(true));
    dispatch(messagePopup(`Caractère utilisé non autorisé`));
    dispatch(messageLevel("warning"));
  };

  const handleEmail = (e) => {
    const resultCheck = checkRegex(e.target.value);
    if (!resultCheck) {
      message();
    } else {
      setEmail(e.target.value);
    }
  };

  const handlePassword = (e) => {
    const resultCheck = checkRegex(e.target.value);
    if (!resultCheck) {
      message();
    } else {
      setPassword(e.target.value);
    }
  };

  const handleSubmit = () => {
    if (!email && !password) {
      dispatch(openPopup(true));
      dispatch(messagePopup(`Vous devez renseigner un email et mot de passe`));
      dispatch(messageLevel("warning"));
    } else {
      axios
        .post(`${API_URL}/api/users/login`, {
          email,
          password,
        })
        .then((res) => res.data)
        .then((data) => {
          localStorage.setItem("afmfToken", data.token);
          localStorage.setItem("afmfId", data.id);
          localStorage.setItem("afmfRole", data.role);
          history.push("/admin/accueil");
        })
        .catch((err) => {
          Catch(err, dispatch, history);
        });
    }
  };

  const displayPword = () => {
    setPwordDisplay(!pwordDisplay);
  };

  return (
    <section>
      <PopupMessage />
      <Helmet>
        <title>Connexion</title>
      </Helmet>
      <div className="mobil">
        <img src={logo} alt="Logo AFMF" className="logoMobil" />
        <p className="msgMobil">
          Vous devez vous connecter depuis un ordinateur
        </p>
      </div>
      <section className="loginSection">
        <img src={logo} alt="Logo AFMF" className="logoDesktop" />
        <form className="containerForm">
          <label htmlFor="email" className="labelForm">
            <p className={email ? "placeholderDisplay" : "placeholderHidden"}>
              Email
            </p>
            <input
              type="text"
              value={email}
              onChange={(e) => handleEmail(e)}
              className="inputLogin"
              placeholder="Email"
            />
          </label>
          <label htmlFor="password" className="labelForm">
            <p
              className={password ? "placeholderDisplay" : "placeholderHidden"}
            >
              Mot de passse
            </p>
            <input
              type={pwordDisplay ? "text" : "password"}
              value={password}
              onChange={(e) => handlePassword(e)}
              className="inputLogin"
              placeholder="Mot de passe"
            />
            <button
              type="button"
              className="pwordDisplay"
              onClick={displayPword}
            >
              {pwordDisplay ? <RiEyeCloseFill /> : <RiEyeFill />}
            </button>
          </label>
        </form>
        <button type="button" className="btnGeneral" onClick={handleSubmit}>
          Soumettre
        </button>
        <Link to="/forgetPassword" className="forgetPassword">
          Mot de passe oublié
        </Link>
      </section>
    </section>
  );
}

export default Login;
