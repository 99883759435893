import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Catch from "../../0.Commun/Catch/Catch";
import { newName } from "../../0.Commun/NewName/NewName";
import Footer from "../0.Commun/Footer/Footer";
import Navbar from "../0.Commun/Navbar/Navbar";
import "./scss/SponsorsDesktop.scss";
import "./scss/SponsorsMobil.scss";

const API_URL = process.env.REACT_APP_API_URL;

function Sponsors() {
  const [sponsors, setSponsors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const getSponsors = () => {
    axios
      .get(`${API_URL}/api/sponsors`)
      .then((res) => res.data)
      .then((data) => {
        setSponsors(data);
        setIsLoading(true);
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  useEffect(() => {
    getSponsors();
  }, []);

  return (
    <section className="sponsorsPublic">
      <Navbar />
      <Helmet>
        <title>Ils soutiennent l&#39;AFMF</title>
      </Helmet>
      <h2 className="titleSponsors">Ils soutiennent l&#39;AFMF</h2>
      <div className="allSponsors">
        {isLoading && (
          <div className="containerImg">
            {sponsors.map((sponsor) => (
              <div className="oneSponsor" key={sponsor.id}>
                <img
                  src={`${API_URL}/images/${sponsor.image_name}`}
                  alt={sponsor.image_alt}
                  className="imgSponsor"
                />
                <p className="subtitle">{newName(sponsor.image_alt)}</p>
              </div>
            ))}
          </div>
        )}
      </div>
      <Footer />
    </section>
  );
}

export default Sponsors;
