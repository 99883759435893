import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactQuill from "react-quill";
import Catch from "../../../0.Commun/Catch/Catch";
import "react-quill/dist/quill.snow.css";
import {
  messageLevel,
  messagePopup,
  openPopup,
} from "../../../0.Commun/Redux/Action";

const API_URL = process.env.REACT_APP_API_URL;

function LegalNotice() {
  const [legalNotice, setLegalNotice] = useState({});
  const [text, setText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const toolbarOptions = {
    toolbar: [
      [{ header: [2, false] }],
      ["bold", "italic", "underline"],
      ["link"],
    ],
  };

  const getInfos = () => {
    axios
      .get(`${API_URL}/api/otherTexts/4`)
      .then((res) => res.data)
      .then((data) => {
        setText(data.text);
        setLegalNotice(data);
        setIsLoading(true);
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  useEffect(() => {
    getInfos();
  }, []);

  const updateLegalNotice = () => {
    const token = localStorage.getItem("afmfToken");
    axios
      .put(
        `${API_URL}/api/otherTexts/4`,
        {
          title: legalNotice.title,
          text,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        dispatch(openPopup(true));
        dispatch(messagePopup(`Mentions légales modifiées`));
        dispatch(messageLevel("good"));
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  return (
    <section className="legalNoticeAdmin">
      <h3 className="title">Mentions légales</h3>
      {isLoading && (
        <ReactQuill
          theme="snow"
          modules={toolbarOptions}
          value={text}
          onChange={setText}
          className="quillDisplay"
        />
      )}
      <button
        type="button"
        className="btnGeneral btnModifyLegalNotice"
        onClick={updateLegalNotice}
      >
        Modifier
      </button>
    </section>
  );
}

export default LegalNotice;
