import React from "react";
import "./NotFound.scss";
import logo from "../../../assets/logoAFMF.png";
// import Navbar from "../0.Commun/Navbar/Navbar";

function NotFound() {
  return (
    <div className="notFoundPage">
      {/* <Navbar /> */}
      <div className="containerNotFound">
        <img src={logo} alt="logo AFMF" className="imgNotFound" />
        <p className="textNotFound">
          La page que vous demandez n&#39;existe pas
        </p>
      </div>
    </div>
  );
}

export default NotFound;
