import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { BiFilterAlt } from "react-icons/bi";
import Catch from "../../0.Commun/Catch/Catch";
import Filter from "../../0.Commun/Filter/Filter";
import PopupMessage from "../../0.Commun/PopupMessage/PopupMessage";
import {
  messageLevel,
  messagePopup,
  openPopup,
} from "../../0.Commun/Redux/Action";
import Footer from "../0.Commun/Footer/Footer";
import Navbar from "../0.Commun/Navbar/Navbar";
import "./scss/SouvenirsDesktop.scss";
import "./scss/SouvenirsMobil.scss";

const API_URL = process.env.REACT_APP_API_URL;

function Souvenirs() {
  const [pageName, setPageName] = useState("");
  const [nbArticles, setNbArticles] = useState(5);
  const [articles, setArticles] = useState({});
  const [isLoadingArticles, setIsLoadingArticles] = useState(false);
  const [themeSelected, setThemeSelected] = useState(0);
  const [openModalFilter, setOpenModalFilter] = useState(false);
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();

  const getUrl = () => {
    return new Promise((resolve) => {
      const newPage = url.split("/").pop();
      resolve(setPageName(newPage));
    });
  };

  const category = () => {
    return new Promise((resolve) => {
      let newCat = 0;
      if (pageName === "actus") {
        newCat = 1;
      }
      resolve(newCat);
    });
  };

  const getArticles = async () => {
    const bool = await category();
    if (themeSelected === 0) {
      axios
        .get(`${API_URL}/api/articles/getAll?actu=${bool}&length=${nbArticles}`)
        .then((res) => res.data)
        .then((data) => {
          setArticles(data);
          setIsLoadingArticles(true);
        })
        .catch((err) => {
          Catch(err, dispatch, history);
        });
    } else {
      axios
        .get(
          `${API_URL}/api/articles/getByTheme?themeId=${themeSelected}&actu=${bool}&length=${nbArticles}`
        )
        .then((res) => res.data)
        .then((data) => {
          setArticles(data);
          setIsLoadingArticles(true);
        })
        .catch((err) => {
          if (err.response.status === 404) {
            setIsLoadingArticles(false);
            setArticles({});
            dispatch(openPopup(true));
            dispatch(
              messagePopup(`Il n'y a pas de contenus pour cette catégorie`)
            );
            dispatch(messageLevel("warning"));
          } else {
            Catch(err, dispatch, history);
          }
        });
    }
  };

  useEffect(async () => {
    getUrl();
  }, [url]);

  useEffect(() => {
    if (pageName !== "") {
      getArticles();
    }
    if (openModalFilter) {
      setOpenModalFilter(false);
    }
  }, [pageName, themeSelected, nbArticles]);

  const openFilter = () => {
    setOpenModalFilter(!openModalFilter);
  };

  const moreArticles = () => {
    setNbArticles(nbArticles + 5);
  };

  return (
    <section className="souvenirSection">
      <PopupMessage />
      <Helmet>
        <title>{pageName === "actus" ? "Actualités AFMF" : "Souvenirs"}</title>
        <meta name="description" content="A compléter" />
        <meta name="keyword" content="A compléter" />
      </Helmet>
      <Navbar />
      <h2 className="titlePage">
        {pageName === "actus" ? "Dernières actualitées" : "Souvenirs"}
      </h2>
      <div className="containerGeneral">
        <div className="containerBtnFilter">
          <div className="icons">
            <BiFilterAlt />
          </div>
          <button type="button" className="btnFilter" onClick={openFilter}>
            Filtre
          </button>
        </div>
        {isLoadingArticles && (
          <div className="containerArticles">
            {articles.map((article) => (
              <Link
                to={`/articles/${article.articleId}/${article.title.replace(
                  /\s/g,
                  "_"
                )}`}
                className="oneArticle"
                key={article.articleId}
              >
                <img
                  src={`${API_URL}/images/${article.name}`}
                  alt={article.alt}
                  className="imgArticle"
                />
                <h4 className="titleArticle">{article.title}</h4>
              </Link>
            ))}
            {articles.length === nbArticles ? (
              <button
                type="button"
                className="btnGeneral"
                onClick={moreArticles}
              >
                Voir plus d&#39;articles
              </button>
            ) : (
              <></>
            )}
          </div>
        )}
        <div className={openModalFilter ? "filterOpen" : "filterDisplay"}>
          <Filter setThemeSelected={setThemeSelected} />
        </div>
      </div>
      <Footer />
    </section>
  );
}

export default Souvenirs;
