import React from "react";
import PropType from "prop-types";
import "./CardAdmin.scss";
import { useDispatch } from "react-redux";
import { messagePopup, openConfirm } from "../../../../0.Commun/Redux/Action";

function CardAdmin({
  member,
  setMemberToDelete,
  setOpenModify,
  setMemberToModify,
}) {
  const dispatch = useDispatch();

  const deleteCard = (cardId) => {
    setMemberToDelete(cardId);
    dispatch(openConfirm(true));
    dispatch(messagePopup(`Etes-vous sur de vouloir supprimer ce membre ?`));
  };

  const modifyCard = (cardId) => {
    setOpenModify(true);
    setMemberToModify(cardId);
  };

  return (
    <div className="card">
      <p className="role">{member.role}</p>
      <p>{member.nom}</p>
      <p>{member.sous_role}</p>
      <p>{member.email}</p>
      <div className="containerBtn">
        <button
          type="button"
          className="BtnCard"
          onClick={() => modifyCard(member.id)}
        >
          &#9997;
        </button>
        <button
          type="button"
          className="BtnCard"
          onClick={() => deleteCard(member.id)}
          disabled={member.id === 1 && true}
        >
          &#10008;
        </button>
      </div>
    </div>
  );
}

CardAdmin.propTypes = {
  setMemberToDelete: PropType.func.isRequired,
  setOpenModify: PropType.func.isRequired,
  setMemberToModify: PropType.func.isRequired,
  member: PropType.shape({
    id: PropType.number.isRequired,
    role: PropType.string.isRequired,
    nom: PropType.string.isRequired,
    sous_role: PropType.string,
    email: PropType.string.isRequired,
  }).isRequired,
};

export default CardAdmin;
