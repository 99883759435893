import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import PropType from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { logo } from "../../../../0.Commun/Logo/DetermineLogo";
import "./scss/NavbarDesktop.scss";
import "./scss/NavbarMobil.scss";
import { updateCategories } from "../../../../0.Commun/Redux/Action";
import Catch from "../../../../0.Commun/Catch/Catch";

const API_URL = process.env.REACT_APP_API_URL;

function NavbarDesktop({ displayBackground, accueil }) {
  const [openHover, setOpenHover] = useState(false);
  const categories = useSelector((state) => state.tasks.categories);
  const dispatch = useDispatch();
  const history = useHistory();

  const determineClass = () => {
    if (!displayBackground && accueil) {
      return "displayBgNone";
    }
    if (displayBackground && accueil) {
      return "displayBg";
    }
    return "displayNormal";
  };

  const displayTitle = () => {
    if (!displayBackground && accueil) {
      return <></>;
    }
    return (
      <Link to="/" className="linkTitle">
        Amicale des Fanfares et Majorettes de France
      </Link>
    );
  };

  const openHoverModal = () => {
    setOpenHover(true);
  };

  const closeHoverModal = () => {
    setOpenHover(false);
  };

  const getCategories = () => {
    if (categories.length === undefined) {
      axios
        .get(`${API_URL}/api/categories/getAll`)
        .then((res) => res.data)
        .then((data) => {
          dispatch(updateCategories(data));
        })
        .catch((err) => {
          Catch(err, dispatch, history);
        });
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <div className={determineClass()}>
      <section className="navbarPublic">
        <Link to="/" className="containerLogo">
          <img
            src={logo}
            alt="Amicale des Fanfares et Majorettes de France - AFMF"
            className="logo"
          />
        </Link>
        <div className="containerNav">
          {displayTitle()}
          <div className="items">
            <Link to="/afmf" className="link">
              AFMF
            </Link>
            {accueil ? (
              <a href="#majorettes" className="link">
                Majorettes
              </a>
            ) : (
              <div
                className="containerHover"
                onMouseEnter={openHoverModal}
                onMouseLeave={closeHoverModal}
              >
                <p className="link">Majorettes</p>
                {openHover && (
                  <div className="openHover">
                    {categories.map((category) => (
                      <Link
                        to={`/majorettes/${
                          category.id
                        }/${category.title.replace(/\s/g, "_")}`}
                        className="categoryTitle"
                        key={category.id}
                      >
                        {category.title}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            )}
            <Link to="/souvenirs" className="link">
              Souvenirs
            </Link>
            <Link to="/sponsors" className="link">
              Sponsors
            </Link>
            <Link to="/contact" className="link">
              Contact
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

NavbarDesktop.propTypes = {
  displayBackground: PropType.bool,
  accueil: PropType.bool,
};

NavbarDesktop.defaultProps = {
  displayBackground: false,
  accueil: false,
};

export default NavbarDesktop;
