import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Catch from "../../../0.Commun/Catch/Catch";
import {
  messageLevel,
  messagePopup,
  openPopup,
} from "../../../0.Commun/Redux/Action";
import { checkRegex } from "../../../0.Commun/Verification/Regex";

const API_URL = process.env.REACT_APP_API_URL;

function MailSubject() {
  const [subjects, setSubjects] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openNewSubject, setOpenNewSubject] = useState(false);
  const [newSubject, setNewSubject] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  const getInfos = () => {
    axios
      .get(`${API_URL}/api/mail_subjects`)
      .then((res) => res.data)
      .then((data) => {
        setSubjects(data);
        setIsLoading(true);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setIsLoading(false);
          setSubjects({});
        } else {
          Catch(err, dispatch, history);
        }
      });
  };

  useEffect(() => {
    getInfos();
  }, []);

  const openAddSubject = () => {
    setOpenNewSubject(!openNewSubject);
  };

  const handleSubject = (e) => {
    const resultCheck = checkRegex(e.target.value);
    if (!resultCheck) {
      dispatch(openPopup(true));
      dispatch(messagePopup(`Caractère utilisé non autorisé`));
      dispatch(messageLevel("warning"));
    } else {
      setNewSubject(e.target.value);
    }
  };

  const addSubject = () => {
    const token = localStorage.getItem("afmfToken");
    if (!newSubject) {
      dispatch(openPopup(true));
      dispatch(messagePopup(`Veuillez saisir un objet`));
      dispatch(messageLevel("warning"));
    } else {
      axios
        .post(
          `${API_URL}/api/mail_subjects`,
          {
            name: newSubject,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          dispatch(openPopup(true));
          dispatch(messagePopup(`Nouvel objet ajouté`));
          dispatch(messageLevel("good"));
          setNewSubject("");
          setOpenNewSubject(false);
          getInfos();
        })
        .catch((err) => {
          Catch(err, dispatch, history);
        });
    }
  };

  const deleteSubject = (subjectId) => {
    const token = localStorage.getItem("afmfToken");
    axios
      .delete(`${API_URL}/api/mail_subjects/${subjectId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        dispatch(openPopup(true));
        dispatch(messagePopup(`Objet supprimé`));
        dispatch(messageLevel("good"));
        getInfos();
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  return (
    <section className="mailSubjectAdmin">
      <h3 className="title">Objet mail</h3>
      {isLoading && (
        <div className="containerMapSubject">
          {subjects.map((subject) => (
            <div key={subject.id} className="mapSubject">
              <p className="item">{subject.name}</p>
              <button
                type="button"
                className="deleteBtn"
                onClick={() => deleteSubject(subject.id)}
              >
                &#10008;
              </button>
            </div>
          ))}
        </div>
      )}
      <button type="button" className="btnGeneral" onClick={openAddSubject}>
        Ajouter un objet
      </button>
      {openNewSubject && (
        <div className="containerNewSubject">
          <form>
            <label htmlFor="newSubject" className="labelNewSubject">
              <p
                className={
                  newSubject ? "placeholderDisplay" : "placeholderHidden"
                }
              >
                Nouvel object
              </p>
              <input
                type="text"
                id="newSubject"
                name="newSubject"
                placeholder="Nouvel objet"
                value={newSubject}
                onChange={(e) => handleSubject(e)}
                className="inputNewObject"
              />
            </label>
          </form>
          <button
            type="button"
            className="btnGeneral btnAddSubject"
            onClick={addSubject}
          >
            Ajouter
          </button>
        </div>
      )}
    </section>
  );
}

export default MailSubject;
