import React, { useEffect, useState } from "react";
import PropType from "prop-types";
import "./AddMember.scss";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useHistory } from "react-router-dom";
import BtnCloseModal from "../../../0.Commun/BtnCloseModal/BtnCloseModal";
import {
  checkRegex,
  verifEmail,
} from "../../../../0.Commun/Verification/Regex";
import {
  messageLevel,
  messagePopup,
  openPopup,
} from "../../../../0.Commun/Redux/Action";
import Catch from "../../../../0.Commun/Catch/Catch";

const API_URL = process.env.REACT_APP_API_URL;

function AddMember({ status, type, setOpenModal }) {
  const [newMember, setNewMember] = useState({
    role: "",
    sousRole: "",
    nom: "",
    email: "",
    conseil: "",
  });
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setNewMember({ ...newMember, role: status, conseil: type });
  }, []);

  const handleText = (e, cat) => {
    const resultRegex = checkRegex(e.target.value);
    if (!resultRegex) {
      dispatch(openPopup(true));
      dispatch(messagePopup(`Caractère utilisé non autorisé`));
      dispatch(messageLevel("warning"));
    } else if (cat === "role") {
      setNewMember({ ...newMember, role: e.target.value });
    } else if (cat === "sousRole") {
      setNewMember({ ...newMember, sousRole: e.target.value });
    } else if (cat === "nom") {
      setNewMember({ ...newMember, nom: e.target.value });
    } else if (cat === "email") {
      setNewMember({ ...newMember, email: e.target.value });
    }
  };

  const handleSubmit = () => {
    const token = localStorage.getItem("afmfToken");
    const resultRegex = verifEmail(newMember.email);
    if (!newMember.nom || !newMember.email) {
      dispatch(openPopup(true));
      dispatch(messagePopup(`Veuillez renseigner tous les champs`));
      dispatch(messageLevel("warning"));
    } else if (!resultRegex) {
      dispatch(openPopup(true));
      dispatch(messagePopup(`Veuillez renseigner un email correct`));
      dispatch(messageLevel("warning"));
    } else {
      axios
        .post(
          `${API_URL}/api/organigrammes`,
          {
            role: newMember.role,
            sousRole: newMember.sousRole,
            nom: newMember.nom,
            email: newMember.email,
            conseil: newMember.conseil,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          setOpenModal(false);
          dispatch(openPopup(true));
          dispatch(messagePopup(`Nouveau membre ajouté`));
          dispatch(messageLevel("good"));
        })
        .catch((err) => {
          Catch(err, dispatch, history);
        });
    }
  };

  return (
    <section className="addMember">
      <div className="bg">
        <div className="containerAddMember">
          <BtnCloseModal setOpenModal={setOpenModal} />
          <h4>{`Ajouter un membre dans ${type}`}</h4>
          <form className="formAddMember">
            <label className="label" htmlFor="role">
              <input
                type="text"
                className="input"
                id="role"
                name="role"
                placeholder="Rôle"
                value={status !== "" ? status : newMember.role}
                onChange={(e) => handleText(e, "role")}
              />
            </label>
            <label className="label" htmlFor="sousRole">
              <input
                type="text"
                className="input"
                id="sousRole"
                name="sousRole"
                placeholder="Sous rôle"
                value={newMember.sousRole}
                onChange={(e) => handleText(e, "sousRole")}
              />
            </label>
            <label className="label" htmlFor="nom">
              <input
                type="text"
                className="input"
                id="nom"
                name="nom"
                placeholder="Nom"
                value={newMember.nom}
                onChange={(e) => handleText(e, "nom")}
              />
            </label>
            <label className="label" htmlFor="email">
              <input
                type="text"
                className="input"
                id="email"
                name="email"
                placeholder="Email"
                value={newMember.email}
                onChange={(e) => handleText(e, "email")}
              />
            </label>
          </form>
          <button type="button" className="btnGeneral" onClick={handleSubmit}>
            Ajouter
          </button>
        </div>
      </div>
    </section>
  );
}

AddMember.propTypes = {
  status: PropType.string,
  type: PropType.string.isRequired,
  setOpenModal: PropType.func.isRequired,
};

AddMember.defaultProps = {
  status: "",
};

export default AddMember;
