import React, { useEffect, useState } from "react";
import PropType from "prop-types";
import axios from "axios";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import BtnCloseModal from "../../0.Commun/BtnCloseModal/BtnCloseModal";
import Quill from "../../0.Commun/Quill/Quill";
import Catch from "../../../0.Commun/Catch/Catch";
import { checkRegex } from "../../../0.Commun/Verification/Regex";
import {
  messageLevel,
  messagePopup,
  openPopup,
} from "../../../0.Commun/Redux/Action";
import verifyImage from "../../../0.Commun/Verification/VerifyImage";
import { newName } from "../../../0.Commun/NewName/NewName";
import MyCalendar from "../../../0.Commun/Calendar/MyCalendar";

const API_URL = process.env.REACT_APP_API_URL;

function ModifyArticle({ setOpenModifyArticle, articleToModify }) {
  const [article, setArticle] = useState({});
  const [text, setText] = useState("");
  const [date, setDate] = useState({});
  const [isLoadingArticle, setIsLoadingArticle] = useState(false);
  const [themes, setThemes] = useState({});
  const [isLoadingThemes, setIsLoadingThemes] = useState(false);
  const [selectedTheme, setSelectedThemes] = useState(0);
  const [newImg, setNewImg] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();

  const makeArticle = (data) => {
    const newArray = [];
    data.forEach((element) => {
      const articleExist = newArray.find(
        (item) => item.id === element.articleId
      );
      const articleIndex = newArray.findIndex(
        (item) => item.id === element.articleId
      );
      if (!articleExist) {
        newArray.push({
          id: element.articleId,
          title: element.title,
          actuality: element.actuality,
          themeId: element.theme_id,
          images: [
            {
              id: element.imageId,
              name: element.name,
              alt: element.alt,
              first: element.first,
            },
          ],
        });
        setText(element.text);
        setDate(new Date(element.date).toISOString());
      } else {
        newArray[articleIndex].images.push({
          id: element.imageId,
          name: element.name,
          alt: element.alt,
          first: element.first,
        });
      }
      setArticle(Object.assign(...newArray));
      setIsLoadingArticle(true);
    });
  };

  const getArticle = () => {
    axios
      .get(`${API_URL}/api/articles/getOne/${articleToModify}`)
      .then((res) => res.data)
      .then((data) => {
        makeArticle(data);
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  useEffect(() => {
    getArticle();
  }, []);

  const newThemes = (oldThemes) => {
    const newArray = [];
    for (let i = 0; i < oldThemes.length; i += 1) {
      if (article.themeId === oldThemes[i].id) {
        setSelectedThemes({
          id: oldThemes[i].id,
          name: oldThemes[i].name,
        });
      } else {
        newArray.push({
          id: oldThemes[i].id,
          name: oldThemes[i].name,
        });
      }
    }
    setThemes(newArray);
    setIsLoadingThemes(true);
  };

  const getThemes = () => {
    axios
      .get(`${API_URL}/api/themes`)
      .then((res) => res.data)
      .then((data) => {
        newThemes(data);
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  useEffect(() => {
    if (article.length !== 0) {
      getThemes();
    }
  }, [article]);

  const handleTheme = (e) => {
    setArticle({ ...article, themeId: e.target.value });
  };

  const handleTitle = (e) => {
    const result = checkRegex(e.target.value);
    if (!result) {
      dispatch(openPopup(true));
      dispatch(messagePopup(`Caractère utilisé non autorisé`));
      dispatch(messageLevel("warning"));
    } else {
      setArticle({ ...article, title: e.target.value });
    }
  };

  const handleIsActus = (e) => {
    if (e.target.checked === true) {
      setArticle({ ...article, actuality: 1 });
    } else {
      setArticle({ ...article, actuality: 0 });
    }
  };

  const updateArticle = () => {
    const token = localStorage.getItem("afmfToken");
    if (!article.title || !text) {
      dispatch(openPopup(true));
      dispatch(messagePopup(`Veuillez remplir tous les champs`));
      dispatch(messageLevel("warning"));
    } else {
      const newDate = new Date(date);
      axios
        .put(
          `${API_URL}/api/articles/${article.id}`,
          {
            title: article.title,
            text,
            actuality: article.actuality,
            themeId: article.themeId,
            date: newDate.toLocaleDateString().split("/").reverse().join("-"),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          dispatch(openPopup(true));
          dispatch(messagePopup(`Article modifié`));
          dispatch(messageLevel("good"));
        })
        .catch((err) => {
          Catch(err, dispatch, history);
        });
    }
  };

  const deleteImg = (imageInfo) => {
    const token = localStorage.getItem("afmfToken");
    if (imageInfo.first === 0) {
      axios
        .delete(`${API_URL}/api/images/${imageInfo.id}/${imageInfo.name}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          dispatch(openPopup(true));
          dispatch(messagePopup(`Photo supprimée`));
          dispatch(messageLevel("good"));
          getArticle();
        })
        .catch((err) => {
          Catch(err, dispatch, history);
        });
    } else {
      dispatch(openPopup(true));
      dispatch(
        messagePopup(
          `Veuillez d'abord choisir une nouvelle image de couverture`
        )
      );
      dispatch(messageLevel("warning"));
    }
  };

  const handleImg = (e) => {
    const imgArray = [];
    try {
      for (let i = 0; i < e.target.files.length; i += 1) {
        if (verifyImage(e.target.files[i]).response === false) {
          dispatch(openPopup(true));
          dispatch(messagePopup(verifyImage(e.target.files[i]).message));
          dispatch(messageLevel("warning"));
        } else {
          imgArray.push(e.target.files[i]);
        }
      }
    } catch {
      dispatch(openPopup(true));
      dispatch(messagePopup(`Une erreur est survenue, veuillez recommencer`));
      dispatch(messageLevel("warning"));
    } finally {
      setNewImg(imgArray);
    }
  };

  const postImages = () => {
    const token = localStorage.getItem("afmfToken");
    for (let i = 0; i < newImg.length; i += 1) {
      const newImage = new FormData();
      newImage.append("file", newImg[i]);
      axios
        .post(`${API_URL}/api/images/images`, newImage, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data)
        .then((data) => {
          axios
            .post(
              `${API_URL}/api/images/newImg`,
              {
                name: data.imageName,
                alt: newImg[i].name,
                size: data.size,
                first: 0,
                president: 0,
                history: 0,
                articleId: article.id,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then(() => {
              dispatch(openPopup(true));
              dispatch(messagePopup(`Nouvelles photos correctement importées`));
              dispatch(messageLevel("good"));
              getArticle();
            })
            .catch((err) => {
              Catch(err, dispatch, history);
            });
        })
        .catch((err) => {
          Catch(err, dispatch, history);
        });
    }
  };

  const changeFirst = (imageId) => {
    const token = localStorage.getItem("afmfToken");
    for (let i = 0; i < article.images.length; i += 1) {
      if (article.images[i].first === 1) {
        axios
          .put(
            `${API_URL}/api/images/${article.images[i].id}`,
            {
              first: 0,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .catch((err) => {
            Catch(err, dispatch, history);
          });
      }
    }
    axios
      .put(
        `${API_URL}/api/images/${imageId}`,
        {
          first: 1,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        dispatch(openPopup(true));
        dispatch(messagePopup(`Modification enregistrée`));
        dispatch(messageLevel("good"));
        getArticle();
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  return (
    <section className="addArticleGlobal">
      <div className="bg">
        {isLoadingArticle && (
          <div className="containerAddArticle">
            <BtnCloseModal setOpenModal={setOpenModifyArticle} />
            {isLoadingThemes && (
              <select
                name="theme"
                id="theme"
                onChange={(event) => handleTheme(event)}
                className="selectTheme"
              >
                <option value={selectedTheme.id} hidden>
                  {selectedTheme.name}
                </option>
                {themes.map((theme) => (
                  <option key={theme.id} value={theme.id}>
                    {theme.name}
                  </option>
                ))}
              </select>
            )}
            <form className="formArticle">
              <label htmlFor="title">
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Titre"
                  className="inputTitle"
                  value={article.title}
                  onChange={(event) => handleTitle(event)}
                />
              </label>
              <MyCalendar date={date} setDate={setDate} from="modify" />
              <Quill text={text} setText={setText} />
              <label htmlFor="actu" className="labelCheckbox">
                Actualité ?
                <input
                  type="checkbox"
                  id="actu"
                  name="actu"
                  className="inputCheckbox"
                  onChange={(event) => handleIsActus(event)}
                  checked={article.actuality}
                />
              </label>
            </form>
            <button
              type="button"
              className="btnGeneral"
              onClick={updateArticle}
            >
              Modifier
            </button>
            <div className="containerImg">
              {article.images.map((image) => (
                <div className="cartImg" key={image.id}>
                  <img
                    src={`${API_URL}/images/${image.name}`}
                    alt={image.alt}
                    className="imgArticle"
                  />
                  <p className="imgDescription">{newName(image.alt)}</p>
                  <form>
                    <label htmlFor="imgFirst" className="imgFirst">
                      Image principale
                      <input
                        type="checkbox"
                        checked={image.first}
                        id="imgFirst"
                        name="imgFirst"
                        onChange={() => changeFirst(image.id)}
                      />
                    </label>
                  </form>
                  <button
                    type="button"
                    className="btnTrash"
                    onClick={() => deleteImg(image)}
                  >
                    &#128465;
                  </button>
                </div>
              ))}
            </div>
            <form className="containerAddImgBtn">
              <label htmlFor="addImg" className="labelNewImg">
                {newImg.length > 0
                  ? `${newImg.length} photos sélectionnées`
                  : "Veuillez sélectionner vos photos"}
                <input
                  type="file"
                  accept="image/*"
                  id="addImg"
                  name="addImg"
                  className="newImg"
                  onChange={(event) => handleImg(event)}
                  multiple
                />
              </label>
              <button
                type="button"
                className="btnGeneral btnAddImg"
                onClick={postImages}
              >
                Ajouter
              </button>
            </form>
          </div>
        )}
      </div>
    </section>
  );
}

ModifyArticle.propTypes = {
  setOpenModifyArticle: PropType.func.isRequired,
  articleToModify: PropType.number.isRequired,
};

export default ModifyArticle;
