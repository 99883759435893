import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { RiEyeCloseFill, RiEyeFill } from "react-icons/ri";
import { logo } from "../../../0.Commun/Logo/DetermineLogo";
import Catch from "../../../0.Commun/Catch/Catch";
import "./scss/newPasswordDesktop.scss";
import "./scss/newPasswordMobil.scss";
import {
  messageLevel,
  messagePopup,
  openPopup,
} from "../../../0.Commun/Redux/Action";

const API_URL = process.env.REACT_APP_API_URL;

function newPassword() {
  const [tempToken, setTempToken] = useState("");
  const [userid, setUserId] = useState();
  const [password, setPassword] = useState("");
  const [displayPassword, setDisplayPassword] = useState(false);
  const [verif, setVerif] = useState("");
  const [displayVerif, setDisplayVerif] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const redirect = () => {
    setTimeout(() => {
      history.push("/login");
    }, 5000);
  };

  const getUser = () => {
    const token = location.pathname.split("/").pop();
    const [id] = location.pathname.split("/").slice(2, 3);
    setTempToken(token);
    setUserId(id);
    axios
      .get(`${API_URL}/api/users/checkToken/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((err) => {
        Catch(err, dispatch, history);
        if (err.response.status === 403) {
          redirect();
        }
      });
  };

  useEffect(() => {
    getUser();
  }, []);

  const resetPassword = () => {
    if (!password && !verif) {
      dispatch(openPopup(true));
      dispatch(messagePopup(`Veuillez saisir tous les champs`));
      dispatch(messageLevel("warning"));
    } else if (password !== verif) {
      dispatch(openPopup(true));
      dispatch(messagePopup(`Les mots de passes ne correspondent pas`));
      dispatch(messageLevel("warning"));
    } else {
      axios
        .put(
          `${API_URL}/api/users/modifyPword/${userid}`,
          {
            password,
          },
          {
            headers: {
              Authorization: `Bearer ${tempToken}`,
            },
          }
        )
        .then(() => {
          dispatch(openPopup(true));
          dispatch(messagePopup(`Mot de passe modifié`));
          dispatch(messageLevel("good"));
          redirect();
        })
        .catch((err) => {
          Catch(err, dispatch, history);
        });
    }
  };

  const displayPword = () => {
    setDisplayPassword(!displayPassword);
  };

  const displayCheck = () => {
    setDisplayVerif(!displayVerif);
  };

  return (
    <section className="newPassword">
      <Helmet>
        <title>Réinitialisation mot de passe</title>
      </Helmet>
      <img
        src={logo}
        alt="Amicale des Fanfares et Majorettes de France"
        className="logo"
      />
      <form action={resetPassword} className="containerForm">
        <label htmlFor="pword" className="labelNewPword">
          <p className={password ? "placeholderDisplay" : "placeholderHidden"}>
            Mot de passe
          </p>
          <input
            type={displayPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Mot de passe"
            className="inputLogin"
          />
          <button type="button" className="pwordDisplay" onClick={displayPword}>
            {displayPassword ? <RiEyeCloseFill /> : <RiEyeFill />}
          </button>
        </label>
        <label htmlFor="verif" className="labelNewPword">
          <p className={verif ? "placeholderDisplay" : "placeholderHidden"}>
            Confirmation mot de passe
          </p>
          <input
            type={displayVerif ? "text" : "password"}
            value={verif}
            onChange={(e) => setVerif(e.target.value)}
            placeholder="Confirmation mot de passe"
            className="inputLogin"
          />
          <button type="button" className="pwordDisplay" onClick={displayCheck}>
            {displayVerif ? <RiEyeCloseFill /> : <RiEyeFill />}
          </button>
        </label>
      </form>
      <button className="btnGeneral" type="button" onClick={resetPassword}>
        Modifier
      </button>
    </section>
  );
}

export default newPassword;
