import React from "react";
import PropType from "prop-types";
import "./BtnCloseModal.scss";

function BtnCloseModal({ setOpenModal }) {
  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <div className="containerBtnClose">
      <button type="button" onClick={closeModal} className="btnClose">
        &#10008;
      </button>
    </div>
  );
}

BtnCloseModal.propTypes = {
  setOpenModal: PropType.func.isRequired,
};

export default BtnCloseModal;
