import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BsFiletypePdf } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { Helmet } from "react-helmet";
import Catch from "../../0.Commun/Catch/Catch";
import Footer from "../0.Commun/Footer/Footer";
import Navbar from "../0.Commun/Navbar/Navbar";
import "./scss/AfmfDesktop.scss";
import "./scss/AfmfMobil.scss";
import Organigramme from "./Organigramme/Organigramme";

const API_URL = process.env.REACT_APP_API_URL;

function Afmf() {
  const [imgCto, setImgCto] = useState({});
  const [ctoIsLoading, setCtoIsLoading] = useState(false);
  const [imgHistory, setImgHistory] = useState({});
  const [histoIsLoading, setHistoIsLoading] = useState(false);
  const [textCto, setTextCto] = useState({});
  const [textCtoIsLoading, setTextCtoIsLoading] = useState(false);
  const [textOrga, setTextOrga] = useState({});
  const [textOrgaIsLoading, setTextOrgaIsLoading] = useState(false);
  const [textHisto, setTextHisto] = useState({});
  const [textHistoIsLoading, setTextHistoIsLoading] = useState(false);
  const [fileUpload, setFileUpload] = useState({});
  const [fileIsLoading, setFileIsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const getImgCto = () => {
    axios
      .get(`${API_URL}/api/images/getPresident`)
      .then((res) => res.data)
      .then((data) => {
        setImgCto(data);
        setCtoIsLoading(true);
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  const getImgHisto = () => {
    axios
      .get(`${API_URL}/api/images/getHistory`)
      .then((res) => res.data)
      .then((data) => {
        setImgHistory(data);
        setHistoIsLoading(true);
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  const getTextCto = () => {
    axios
      .get(`${API_URL}/api/otherTexts/1`)
      .then((res) => res.data)
      .then((data) => {
        setTextCto(data);
        setTextCtoIsLoading(true);
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  const getTextOrga = () => {
    axios
      .get(`${API_URL}/api/otherTexts/2`)
      .then((res) => res.data)
      .then((data) => {
        setTextOrga(data);
        setTextOrgaIsLoading(true);
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  const getTextHisto = () => {
    axios
      .get(`${API_URL}/api/otherTexts/3`)
      .then((res) => res.data)
      .then((data) => {
        setTextHisto(data);
        setTextHistoIsLoading(true);
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  const getFile = () => {
    axios
      .get(`${API_URL}/api/fileUpload`)
      .then((res) => res.data)
      .then((data) => {
        setFileUpload(data);
        setFileIsLoading(true);
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  const allRequest = async () => {
    getImgCto();
    getImgHisto();
    getTextCto();
    getTextOrga();
    getTextHisto();
    getFile();
  };

  useEffect(() => {
    allRequest();
  }, []);

  useEffect(() => {
    if (
      (ctoIsLoading,
      histoIsLoading,
      textCtoIsLoading,
      textOrgaIsLoading,
      textHistoIsLoading,
      fileIsLoading)
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    ctoIsLoading,
    histoIsLoading,
    textCtoIsLoading,
    textOrgaIsLoading,
    textHistoIsLoading,
    fileIsLoading,
  ]);

  return (
    <section className="afmfSection">
      <Helmet>
        <title>AFMF - Amicale des Fanfares et Majorettes de France</title>
      </Helmet>
      <Navbar />
      <h2 className="title">L&#39;AFMF</h2>
      {isLoading && (
        <div className="containerGeneral">
          <div className="containerCto">
            <img
              src={`${API_URL}/images/${imgCto.name}`}
              alt={imgCto.alt}
              className="imgCto"
            />
            <h3 className="subtitle">{textCto.title}</h3>
            <div className="quillParser">{ReactHtmlParser(textCto.text)}</div>
          </div>
          <div className="containerCto">
            <h3 className="subtitle">{textOrga.title}</h3>
            <Organigramme />
            <div className="containerFile">
              {fileUpload?.map((file) => (
                <div className="btnGeneral oneFile">
                  <BsFiletypePdf className="pdf" />
                  <a
                    href={`${API_URL}/file_upload/${file.name}`}
                    target="blank"
                    key={file.id}
                    className="oneFile"
                  >
                    {file.originalName}
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className="containerCto">
            <h3 className="subtitle">{textHisto.title}</h3>
            <div className="quillParser">{ReactHtmlParser(textHisto.text)}</div>
            <div className="containerImgHisto">
              {imgHistory?.map((imgHis) => (
                <img
                  src={`${API_URL}/images/${imgHis.name}`}
                  alt={imgHistory.alt}
                  className="imgHisto"
                  key={imgHis.id}
                />
              ))}
            </div>
          </div>
        </div>
      )}
      <Footer />
    </section>
  );
}

export default Afmf;
