import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import Catch from "../../0.Commun/Catch/Catch";
import "./scss/AccueilDesktop.scss";
import "./scss/AccueilMobil.scss";
import Footer from "../0.Commun/Footer/Footer";
import Navbar from "../0.Commun/Navbar/Navbar";

const API_URL = process.env.REACT_APP_API_URL;

function Accueil() {
  const [articleHeader, setArticleHeader] = useState({});
  const [articles, setArticles] = useState({});
  const [isLoadingArticle, setIsLoadingArticle] = useState(false);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const categories = useSelector((state) => state.tasks.categories);
  const dispatch = useDispatch();
  const history = useHistory();

  const getArticles = () => {
    axios
      .get(`${API_URL}/api/articles/getFour`)
      .then((res) => res.data)
      .then((data) => {
        const newData = data;
        const header = data.shift();
        setArticles(newData);
        setArticleHeader(header);
        setIsLoadingArticle(true);
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  useEffect(() => {
    getArticles();
    if (categories.length !== undefined) {
      setIsLoadingCategories(true);
    }
  }, [categories]);

  return (
    <section className="accueilSection">
      <Helmet>
        <title>AFMF - Amicale des Fanfares et Majorettes de France</title>
        <meta name="description" content="A compléter" />
        <meta name="keyword" content="A compléter" />
      </Helmet>
      <Navbar accueil />
      {isLoadingArticle && (
        <section>
          <header className="header">
            <img
              src={`${API_URL}/images/${articleHeader.name}`}
              alt={articleHeader.alt}
              className="imgHeader"
            />
            <Link
              className="containerTitleArticle"
              to={`/articles/${
                articleHeader.articleId
              }/${articleHeader.title.replace(/\s/g, "_")}`}
            >
              <p className="subtitle">Dernière actualités</p>
              <h2>{articleHeader.title}</h2>
              <p className="subtitle">Lire l&#39;article...</p>
            </Link>
            <span className="bg" />
            <div className="title">
              <h1>Amicale des Fanfares et Majorettes de France</h1>
            </div>
          </header>
          <h4 className="titleActu">Nos dernières actualités</h4>
          <div className="containerArticles">
            {articles.map((article) => (
              <Link
                to={`/articles/${article.articleId}/${article.title.replace(
                  /\s/g,
                  "_"
                )}`}
                className="oneArticle"
                key={article.articleId}
              >
                <img
                  src={`${API_URL}/images/${article.name}`}
                  alt={article.alt}
                  className="imgArticle"
                />
                <p className="articleTitle">{article.title}</p>
              </Link>
            ))}
          </div>
          <Link to="/actus" className="linkActus" id="majorettes">
            Voir toutes les actualités &#x2794;
          </Link>
        </section>
      )}
      <div className="majoTitle">
        <h2 className="title">Majorettes</h2>
      </div>
      <div className="categories">
        {isLoadingCategories && (
          <div className="containerCategories">
            {categories.map((category) => (
              <Link
                to={`/majorettes/${category.id}/${category.title.replace(
                  /\s/g,
                  "_"
                )}`}
                className="oneCategory"
                key={category.id}
              >
                <img
                  src={`${API_URL}/images/${category.image_name}`}
                  alt={category.image_alt}
                  className="imgCategory"
                />
                <h3>{category.title}</h3>
              </Link>
            ))}
          </div>
        )}
      </div>
      <Footer />
    </section>
  );
}

export default Accueil;
