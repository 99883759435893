import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Catch from "../../../0.Commun/Catch/Catch";
import Card from "./Card/Card";
import "./scss/OrgaDesktop.scss";
import "./scss/OrgaMobil.scss";

const API_URL = process.env.REACT_APP_API_URL;

function Organigramme() {
  const [members, setMembers] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const getMembers = () => {
    axios
      .get(`${API_URL}/api/organigrammes/getAll`)
      .then((res) => res.data)
      .then((data) => {
        setMembers(data);
        setIsLoading(true);
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  useEffect(() => {
    getMembers();
  }, []);

  return (
    <section className="organigrammePublic">
      {isLoading && (
        <div className="containerCards">
          <div className="categories">
            <h4 className="catTitle">Conseil d&#39;administration</h4>
            <Card member={members[0]} />
            <div className="members">
              {members
                .filter(
                  (member) => member.conseil === "conseil" && member.id !== 1
                )
                .map((member) => (
                  <div className="containerCard">
                    <Card member={member} />
                  </div>
                ))}
            </div>
          </div>
          <div className="categories">
            <h4 className="catTitle">Membres accesseurs</h4>
            <div className="members">
              {members
                .filter((member) => member.conseil === "membre")
                .map((member) => (
                  <div className="containerCard">
                    <Card member={member} />
                  </div>
                ))}
            </div>
          </div>
          <div className="categories">
            <h4 className="catTitle">Controleur aux comptes</h4>
            <div className="members">
              {members
                .filter((member) => member.conseil === "controleur")
                .map((member) => (
                  <div className="containerCard">
                    <Card member={member} />
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

export default Organigramme;
