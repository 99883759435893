import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { Helmet } from "react-helmet";
import Catch from "../../0.Commun/Catch/Catch";
import Footer from "../0.Commun/Footer/Footer";
import Navbar from "../0.Commun/Navbar/Navbar";
import "./scss/MajorettesDesktop.scss";
import "./scss/MajorettesMobil.scss";

const API_URL = process.env.REACT_APP_API_URL;

function Majorettes() {
  const [infos, setInfos] = useState({});
  const [isLoadingInfos, setIsLoadingInfos] = useState(false);
  const [articles, setArticles] = useState({});
  const [isLoadingArticles, setIsLoadingArticles] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const getInfos = () => {
    axios
      .get(`${API_URL}/api/categories/getOne/${id}`)
      .then((res) => res.data)
      .then((data) => {
        setInfos(data);
        setIsLoadingInfos(true);
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  const lastArticles = () => {
    axios
      .get(`${API_URL}/api/articles/getByTheme?themeId=${id}&length=5`)
      .then((res) => res.data)
      .then((data) => {
        setArticles(data);
        setIsLoadingArticles(true);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          setIsLoadingArticles(false);
          setArticles({});
        } else {
          Catch(err, dispatch, history);
        }
      });
  };

  useEffect(() => {
    getInfos();
    lastArticles();
  }, [id]);

  const defineNewName = (oldName) => {
    const newName = oldName.split("-");
    // eslint-disable-next-line no-unused-vars
    const name = newName.shift();
    return newName.join("-");
  };

  return (
    <section className="majoretteSection">
      <Helmet>
        <title>{infos.title}</title>
        <meta name="description" content="A compléter" />
        <meta name="keyword" content="A compléter" />
      </Helmet>
      <Navbar />
      {isLoadingInfos && (
        <div className="containerGeneral">
          <h2 className="title">{infos.title}</h2>
          <div className="grid">
            <div className="containerText quillParser">
              {ReactHtmlParser(infos.text)}
            </div>
            {infos.coming_display ? (
              <div className="coming">
                <h3 className="subtitle">{infos.coming_title}</h3>
                <div className="containerTextComing">
                  {ReactHtmlParser(infos.coming_text)}
                </div>
                {infos.filename ? (
                  <a
                    href={`${API_URL}/file_upload/${infos.filename}`}
                    target="blanck"
                    className="file"
                  >
                    {defineNewName(infos.filename)}
                  </a>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
      <h3 className="interTitle">Derniers articles</h3>
      {isLoadingArticles && (
        <div className="containerArticles">
          {articles.map((article) => (
            <Link
              to={`/articles/${article.articleId}/${article.title.replace(
                /\s/g,
                "_"
              )}`}
              className="oneArticle"
              key={article.articleId}
            >
              <img
                src={`${API_URL}/images/${article.name}`}
                alt={article.alt}
                className="imgArticle"
              />
              <p className="articleTitle">{article.title}</p>
            </Link>
          ))}
        </div>
      )}
      <Footer />
    </section>
  );
}

export default Majorettes;
