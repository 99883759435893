import React, { useEffect, useState } from "react";
import PropType from "prop-types";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Catch from "../../../0.Commun/Catch/Catch";
import {
  messageLevel,
  messagePopup,
  openPopup,
} from "../../../0.Commun/Redux/Action";

const API_URL = process.env.REACT_APP_API_URL;

function ModifyUser({ setOpenModalModify, idToModify }) {
  const [user, setUser] = useState({});
  const dispatch = useDispatch();
  const history = useHistory();

  const getUser = () => {
    const token = localStorage.getItem("afmfToken");
    axios
      .get(`${API_URL}/api/users/getOne/${idToModify}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data)
      .then((data) => {
        setUser(data);
      })
      .catch((err) => {
        Catch(err, dispatch, history);
      });
  };

  useEffect(() => {
    getUser();
  }, []);

  const closeModal = () => {
    setOpenModalModify(false);
  };

  const handleSelect = (e) => {
    setUser({ ...user, role: e.target.value });
  };

  const handleSubmit = () => {
    const token = localStorage.getItem("afmfToken");
    if (!user.role) {
      dispatch(openPopup(true));
      dispatch(messagePopup(`Veuillez sélectionner un rôle`));
      dispatch(messageLevel("warning"));
    } else {
      axios
        .put(
          `${API_URL}/api/users/role/${idToModify}`,
          {
            role: user.role,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          dispatch(openPopup(true));
          dispatch(messagePopup(`Nouveau rôle attribué`));
          dispatch(messageLevel("good"));
          closeModal();
        })
        .catch((err) => {
          Catch(err, dispatch, history);
        });
    }
  };

  return (
    <section className="addUserGlobal">
      <div className="bg">
        <div className="containerAddUser">
          <div className="containerBtnClose">
            <button type="button" onClick={closeModal} className="btnClose">
              &#10008;
            </button>
          </div>
          <h3 className="title">Modifier le rôle</h3>
          <form className="containerFormAdd">
            <label htmlFor="role" className="labelUser">
              <select
                name="role"
                id="role"
                className="selectRole"
                onChange={(e) => handleSelect(e)}
              >
                <option value="0">Rôle</option>
                <option value="admin">Administrateur</option>
                <option value="utilisateur">Utilisateur</option>
              </select>
            </label>
          </form>
          <div className="containerBtnAdd">
            <button type="button" className="btnGeneral" onClick={handleSubmit}>
              Modifier
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

ModifyUser.propTypes = {
  setOpenModalModify: PropType.func.isRequired,
  idToModify: PropType.number.isRequired,
};

export default ModifyUser;
