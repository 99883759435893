import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../0.Commun/Navbar/Navbar";
import "./Profil.scss";
import UserTable from "./components/UserTable";
import ModifyProfil from "./components/ModifyProfil";
import PopupMessage from "../../0.Commun/PopupMessage/PopupMessage";
import MailSubject from "./components/MailSubject";
import LegalNotice from "./components/LegalNotice";

function Profil() {
  const role = localStorage.getItem("afmfRole");

  return (
    <section className="profilAdmin">
      <PopupMessage />
      <Navbar />
      <Helmet>
        <title>Admin - Paramètres</title>
      </Helmet>
      <div className="subNavbar">
        <a className={role === "utilisateur" ? "disallowAcces" : ""} href="#1">
          Gestion utilisateur
        </a>
        <a href="#2">Modifier mes informations</a>
        <a className={role === "utilisateur" ? "disallowAcces" : ""} href="#3">
          Objet mail
        </a>
        <a className={role === "utilisateur" ? "disallowAcces" : ""} href="#4">
          Mentions légales
        </a>
      </div>
      <div id="1" className={role === "utilisateur" ? "disallowAcces" : ""}>
        <UserTable />
      </div>
      <hr className={role === "utilisateur" ? "disallowAcces" : ""} />
      <div id="2">
        <ModifyProfil />
      </div>
      <hr className={role === "utilisateur" ? "disallowAcces" : ""} />
      <div id="3" className={role === "utilisateur" ? "disallowAcces" : ""}>
        <MailSubject />
      </div>
      <hr className={role === "utilisateur" ? "disallowAcces" : ""} />
      <div id="4" className={role === "utilisateur" ? "disallowAcces" : ""}>
        <LegalNotice />
      </div>
    </section>
  );
}

export default Profil;
