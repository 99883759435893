import axios from "axios";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Recaptcha from "react-recaptcha";
import Catch from "../../../0.Commun/Catch/Catch";
import Loader from "../../../0.Commun/Loader/Loader";
import { logo } from "../../../0.Commun/Logo/DetermineLogo";
import {
  messageLevel,
  messagePopup,
  openPopup,
} from "../../../0.Commun/Redux/Action";
import { checkRegex, verifEmail } from "../../../0.Commun/Verification/Regex";
import "./scss/forgetPasswordDesktop.scss";
import "./scss/forgetPasswordMobil.scss";

const API_URL = process.env.REACT_APP_API_URL;
const SITEKEY = process.env.REACT_APP_CAPTCHA_SITEKEY;

function forgetPassword() {
  const [email, setEmail] = useState("");
  const [mailSent, setMailSent] = useState(false);
  const [loaderOn, setLoaderOn] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleEmail = (e) => {
    const resultRegex = checkRegex(e.target.value);
    if (!resultRegex) {
      dispatch(openPopup(true));
      dispatch(messagePopup(`Caractère utilisé non autorisé`));
      dispatch(messageLevel("warning"));
    } else {
      setEmail(e.target.value);
    }
  };

  const submitMail = (e) => {
    e.preventDefault();
    const resultRegex = verifEmail(email);
    if (!isVerified) {
      dispatch(openPopup(true));
      dispatch(
        messagePopup(`Veuillez cocher la case "je ne suis pas un robot"`)
      );
      dispatch(messageLevel("warning"));
    } else if (!resultRegex) {
      dispatch(openPopup(true));
      dispatch(messagePopup(`Veuillez saisir un email valide`));
      dispatch(messageLevel("warning"));
    } else {
      setLoaderOn(true);
      axios
        .post(`${API_URL}/api/users/forgetPassword`, { email })
        .then(() => {
          setMailSent(true);
          setLoaderOn(false);
        })
        .catch((err) => {
          if (err.response.status === 404) {
            dispatch(openPopup(true));
            dispatch(messagePopup(`Email non reconnu`));
            dispatch(messageLevel("warning"));
          } else {
            Catch(err, dispatch, history);
          }
        });
    }
  };

  const verifyCallback = () => {
    setIsVerified(true);
  };

  return (
    <section className="forgetSection">
      <Helmet>
        <title>Mot de passe oublié</title>
      </Helmet>
      {loaderOn && <Loader />}
      <img
        src={logo}
        alt="Amicale des Fanfares et Majorettes de France"
        className="logo"
      />
      {mailSent === false && (
        <>
          <form onSubmit={submitMail}>
            <input
              type="text"
              value={email}
              placeholder="Email"
              onChange={(event) => handleEmail(event)}
              className="inputForget"
            />
          </form>
          <Recaptcha
            sitekey={SITEKEY}
            render="explicit"
            hl="fr"
            verifyCallback={verifyCallback}
          />
          <button className="btnGeneral" type="button" onClick={submitMail}>
            Envoyer
          </button>
        </>
      )}
      {mailSent && <p>Un email vient de vous être envoyé</p>}
    </section>
  );
}

export default forgetPassword;
