import React, { useEffect } from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import PropType from "prop-types";

function CookieAcceptance({ setCookie }) {
  useEffect(() => {
    const getCookie = Cookies.get("CookieConsent");
    if (getCookie !== undefined) {
      setCookie(/true/i.test(getCookie));
    }
  }, []);

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accepter"
      style={{ background: "#80acdb", textAlign: "center" }}
      buttonStyle={{ background: "#0059b8", color: "#ffffff" }}
      expires={365}
    >
      Ce site utilise des cookies.{" "}
      <span style={{ fontSize: "10px" }}>
        Nous les utilisons afin de mesurer l&#39;audience de notre site
      </span>
    </CookieConsent>
  );
}

CookieAcceptance.propTypes = {
  setCookie: PropType.func.isRequired,
};

export default CookieAcceptance;
