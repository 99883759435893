import React from "react";
import PropType from "prop-types";
import "./scss/CardDesktop.scss";
import "./scss/CardMobil.scss";

function Card({ member }) {
  return (
    <div className="cardPublic">
      <p className="role">{member.role}</p>
      <p>{member.nom}</p>
      <p>{member.sous_role}</p>
      <p>{member.email}</p>
    </div>
  );
}

Card.propTypes = {
  member: PropType.shape({
    role: PropType.string.isRequired,
    nom: PropType.string.isRequired,
    sous_role: PropType.string,
    email: PropType.string.isRequired,
  }).isRequired,
};

export default Card;
